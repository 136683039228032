import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Component } from "react";
import logoPallua from '../../../assets/images/logo-pallua.png';
import { Sidebar } from 'primereact/sidebar';
import { Link, Navigate } from "react-router-dom";
import Item from "lirida-back-service/Servicos/Item/Item";
import Util from "../../../util/Util";

export default class Header extends Component {
    util = new Util();

    state = {
        item: new Item(),
        conteudo: '',
        sidebarVisivel: false,
        liberarAcesso: {
            principal: false,
            quem_somos: false,
            depoimentos: false,
            pesquisa: false,
            login: false,
            cadastro: false,
            cobranca: false,
        }
    }

    componentDidMount() {
        this.iniciar();
    }

    iniciar() {
        const parametros = this.util.buscarParametros();
        let conteudo = "";

        if (parametros?.q) {
            conteudo = parametros.q;
        }

        this.setState({ conteudo: conteudo });
    }

    navegar(pTela) {
        const liberarAcesso = this.state.liberarAcesso;
        const path_atual = window.location.pathname.split("/")[1];

        if (pTela === "pesquisa") {
            window.location.href = "/pesquisa?q=" + this.state.conteudo
        }

        if (pTela === path_atual) return;
        if (pTela === "principal" && !path_atual) return;


        liberarAcesso[pTela] = true;

        this.setState({ liberarAcesso: liberarAcesso });
    }

    render() {
        return <>
            {this.state.liberarAcesso.principal && <Navigate to="/" />}
            {this.state.liberarAcesso.quem_somos && <Navigate to="/quem_somos" />}
            {this.state.liberarAcesso.depoimentos && <Navigate to="/depoimentos" />}
            {this.state.liberarAcesso.login && <Navigate to="/login" />}
            {this.state.liberarAcesso.cadastro && <Navigate to="/cadastro" />}
            {this.state.liberarAcesso.cobranca && <Navigate to={"/cobranca?id=" + this.state.item._seqItem} />}

            <Sidebar visible={this.state.sidebarVisivel} onHide={() => this.setState({ sidebarVisivel: false })}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 50 }}>
                    <nav style={{ display: 'flex', flexDirection: 'column', gap: 50 }}>
                        <Link to="/" style={{ fontWeight: 'bold', color: 'var(--primary-800)', fontSize: 13, textDecoration: 'none', marginRight: 50 }}>
                            Principal
                        </Link>
                        <Link to="/quem_somos" style={{ fontWeight: 'bold', color: 'var(--primary-800)', fontSize: 13, textDecoration: 'none', marginRight: 50 }}>
                            Quem somos
                        </Link>
                        <Link to="/depoimentos" style={{ fontWeight: 'bold', color: 'var(--primary-800)', fontSize: 13, textDecoration: 'none', marginRight: 50 }}>
                            Depoimentos
                        </Link>
                    </nav>

                    <form className="col-12 md:col-4" onSubmit={e => { e.preventDefault(); this.navegar("pesquisa") }}>
                        <div className="p-inputgroup">
                            <InputText
                                placeholder="Pesquisar"
                                onChange={e => this.setState({ conteudo: e.target.value })}
                                value={this.state.conteudo} />
                            <Button icon="pi pi-search" type="submit" />
                        </div>
                    </form>

                    <div style={{ display: 'flex', flexDirection: 'column', gap: 50 }}>
                        <Link to="/login" style={{ fontWeight: 'bold', color: 'var(--primary-800)', fontSize: 13, textDecoration: 'none', marginRight: 50 }}>
                            Login
                        </Link>

                        <Button
                            label="Criar Conta"
                            className="p-button-warning"
                            style={{ fontSize: 13 }}
                            onClick={() => this.navegar("cadastro")} />
                    </div>
                </div>
            </Sidebar>

            <header className="h-header">
                <img src={logoPallua} style={{ width: 100, objectFit: 'contain', cursor: 'pointer' }} alt="" onClick={() => window.location.href = "/"}/>

                <nav style={{ width: '30%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Link className="h-header-link" to="/" style={{ fontWeight: 'bold', color: 'var(--primary-800)', fontSize: 13, textDecoration: 'none', }}>
                        Principal
                    </Link>
                    <Link className="h-header-link" to="/quem_somos" style={{ fontWeight: 'bold', color: 'var(--primary-800)', fontSize: 13, textDecoration: 'none', }}>
                        Quem somos
                    </Link>
                    <Link className="h-header-link" to="/depoimentos" style={{ fontWeight: 'bold', color: 'var(--primary-800)', fontSize: 13, textDecoration: 'none', }}>
                        Depoimentos
                    </Link>
                </nav>

                <form className="col-12 md:col-4 h-header-form" onSubmit={e => { e.preventDefault(); this.navegar("pesquisa") }}>
                    <div className="p-inputgroup">
                        <InputText
                            placeholder="Pesquisar"
                            onChange={e => this.setState({ conteudo: e.target.value })}
                            value={this.state.conteudo} />
                        <Button icon="pi pi-search" type="submit" />
                    </div>
                </form>


                <Link className="h-header-link" to="/login" style={{ fontWeight: 'bold', color: 'var(--primary-800)', fontSize: 13, textDecoration: 'none' }}>
                    Login
                </Link>

                <div className="p-button-warning h-header-button">
                    <Button
                        label="Criar Conta"
                        className="p-button-warning"
                        style={{ fontSize: 13 }}
                        onClick={() => this.navegar("cadastro")} />
                </div>

                <div className="h-header-menu-button">
                    <Button
                        icon={<i className="pi pi-bars"></i>}
                        onClick={() => this.setState({ sidebarVisivel: true })} />
                </div>
            </header>
            <div className="h-header-barra" />
        </>
    }
}