import ItemCategoria from "lirida-back-service/Servicos/ItemCategoria/ItemCategoria";
import Item from "lirida-back-service/Servicos/Item/Item";
import { Component } from "react";
import Footer from "./components/Footer";
import Header from "./components/Header";
import ProjetosHotsite from "./components/ProjetosHotsite";
import Util from "../../util/Util";
import Constante from "../../util/Constante";
import ItemService from "lirida-back-service/Servicos/Item/ItemService";
import ItemCategoriaService from "lirida-back-service/Servicos/ItemCategoria/ItemCategoriaService";

export default class PesquisaPg extends Component {
    util = new Util();
    constante = new Constante();
    itemService = new ItemService(this.constante.token, this.constante.url_base);
    itemCategoriaService = new ItemCategoriaService(this.constante.token, this.constante.url_base);

    state = {
        conteudo: '',
        urlBase: '',
        listaItem: [],
        listaItemCategoria: [],
        item: new Item(),
        itemCategoria: new ItemCategoria(),
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        const urlBase = await this.util.buscarUrlBaseArquivo();
        const parametros = await this.util.buscarParametros();

        await this.listarItem(parametros?.q, parametros?.ic);
        this.listarItemCategoria();

        this.setState({ urlBase: urlBase });
    }

    async listarItem(pConteudo, pSeqItemCategoria) {
        const listaPesquisa = [];

        listaPesquisa.push(
            this.util.retornaPesquisa(
                "item.seq_usuario",
                this.constante.seqUsuario,
                "",
                "TEXTO"
            )
        )

        listaPesquisa.push(
            this.util.retornaPesquisa(
                "item.situacao",
                "ATIVO",
                "AND",
                "TEXTO"
            )
        )

        listaPesquisa.push(
            this.util.retornaPesquisa(
                "item.nome",
                pConteudo,
                "AND",
                "TEXTO"
            )
        )
        
        listaPesquisa.push(
            this.util.retornaPesquisa(
                "item_caracteristica.seq_usuario",
                this.constante.seqUsuario,
                "AND",
                "TEXTO"
            )
        )
        
        listaPesquisa.push(
            this.util.retornaPesquisa(
                "item_caracteristica.seq_item_caracteristica",
                this.constante.seqItemCaracteristica,
                "AND",
                "TEXTO"
            )
        )

        if (pSeqItemCategoria) {
            listaPesquisa.push(
                this.util.retornaPesquisa(
                    "item.seq_item_categoria",
                    pSeqItemCategoria,
                    "AND",
                    "TEXTO"
                )
            )
        }

        listaPesquisa.push(
            this.util.retornaPesquisa(
                "item.nome",
                pConteudo,
                "AND",
                "TEXTO"
            )
        )

        const retornoItem = await this.itemService.listarComFiltro(listaPesquisa);
        const listaItem = retornoItem.objeto;

        this.setState({ listaItem: listaItem });
    }

    async listarItemCategoria() {
        const retornoItemCategoria = await this.itemCategoriaService.listar(this.constante.seqUsuario);
        const listaItemCategoria = retornoItemCategoria.objeto;

        this.setState({ listaItemCategoria: listaItemCategoria });
    }

    navegar(pTela) {
        const liberarAcesso = this.state.liberarAcesso;
        const path_atual = window.location.pathname.split("/")[1];

        if (pTela === "pesquisa") {
            window.location.href = "/pesquisa?q=" + this.state.conteudo;
        }

        if (pTela === path_atual) return;
        if (pTela === "principal" && !path_atual) return;


        liberarAcesso[pTela] = true;

        this.setState({ liberarAcesso: liberarAcesso });
    }

    render() {
        return <div>

            <Header />

            <ProjetosHotsite
                listaItemCategoria={this.state.listaItemCategoria}
                listaItem={this.state.listaItem}
                urlBase={this.state.urlBase}
            />

            <Footer />
        </div>
    }
}