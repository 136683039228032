import { Component, createRef } from "react";
import Util from "../../util/Util";
import Constante from "../../util/Constante";
import ItemCategoriaService from "lirida-back-service/Servicos/ItemCategoria/ItemCategoriaService";
import ItemService from "lirida-back-service/Servicos/Item/ItemService";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import Footer from "./components/Footer";
import Header from "./components/Header";
import topo1 from '../../assets/images/topo1.jpg';
import ProjetosHotsite from "./components/ProjetosHotsite";
export default class Principal extends Component {
    util = new Util();
    constante = new Constante();
    itemCategoriaService = new ItemCategoriaService(this.constante.token, this.constante.url_base);
    itemService = new ItemService(this.constante.token, this.constante.url_base);
    mensagensLogin = createRef();
    mensagensCadastro = createRef();
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base);

    state = {
        urlBase: '',
        conteudo: '',
        listaItemCategoria: [],
        listaItem: [],
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        const urlBase = await this.util.buscarUrlBaseArquivo();

        await this.listarItem();
        this.listarItemCategoria();

        this.setState({ urlBase: urlBase });
    }

    async listarItem(pConteudo, pSeqItemCategoria) {
        const listaPesquisa = [];

        listaPesquisa.push(
            this.util.retornaPesquisa(
                "item.seq_usuario",
                this.constante.seqUsuario,
                "",
                "TEXTO"
            )
        )

        listaPesquisa.push(
            this.util.retornaPesquisa(
                "item.situacao",
                "ATIVO",
                "AND",
                "TEXTO"
            )
        )

        listaPesquisa.push(
            this.util.retornaPesquisa(
                "item.nome",
                pConteudo,
                "AND",
                "TEXTO"
            )
        )

        listaPesquisa.push(
            this.util.retornaPesquisa(
                "item_caracteristica.seq_usuario",
                this.constante.seqUsuario,
                "AND",
                "TEXTO"
            )
        )

        listaPesquisa.push(
            this.util.retornaPesquisa(
                "item_caracteristica.seq_item_caracteristica",
                this.constante.seqItemCaracteristica,
                "AND",
                "TEXTO"
            )
        )

        if (pSeqItemCategoria) {
            listaPesquisa.push(
                this.util.retornaPesquisa(
                    "item.seq_item_categoria",
                    pSeqItemCategoria,
                    "AND",
                    "TEXTO"
                )
            )
        }

        listaPesquisa.push(
            this.util.retornaPesquisa(
                "item.nome",
                pConteudo,
                "AND",
                "TEXTO"
            )
        )

        const retornoItem = await this.itemService.listarComFiltro(listaPesquisa);
        const listaItem = retornoItem.objeto;

        console.log(retornoItem)

        this.setState({ listaItem: listaItem });
    }

    async listarItemCategoria() {
        const retornoItemCategoria = await this.itemCategoriaService.listar(this.constante.seqUsuario);
        const listaItemCategoria = retornoItemCategoria.objeto;

        this.setState({ listaItemCategoria: listaItemCategoria });
    }

    render() {
        return <div>
            <Header />

            <img src={topo1} style={{ width: '100%', objectFit: 'contain' }} alt="" />

            <ProjetosHotsite
                listaItemCategoria={this.state.listaItemCategoria}
                listaItem={this.state.listaItem}
                urlBase={this.state.urlBase}
            />

            <Footer />
        </div>
    }
}