import { Component } from "react";
import Footer from "./components/Footer";
import Header from "./components/Header";

export default class TermosUso extends Component {
    render() {
        return <div>
            <Header />

            <main style={{ backgroundColor: '#FFBB05', padding: 50, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 50 }}>
                <h1 style={{ color: 'white' }}>Termos de uso</h1>
                <div style={{ width: 1000, backgroundColor: 'white', borderRadius: 15, border: '1px solid gray', color: 'gray', padding: 20, fontSize: 17, display: "flex", flexDirection: 'column', gap: 10 }}>
                    <p>Iniciando o uso do website da PALLUA e dos serviços disponibilizados por ele, você concorda e estará sujeito a todos os termos, políticas e condições gerais descritas abaixo.</p>

                    <p>1. Objeto</p>
                    <p>1.1 A PALLUA é uma ferramenta online de captação de recursos financeiro de forma simples e fácil.</p>
                    <p>1.2 A PALLUA possibilita a criação da página pública de projetos, possui opção para recebimento de doações e vendas online através de uma aplicação web na internet, acessível a partir do endereço https://www.pallua.com.br</p>
                    <br /><br />

                    <p>2. Propriedade</p>
                    <p>2.1 A PALLUA foi desenvolvido pela CROSSSYSTEM TECNOLOGIA DA INFORMAÇÃO LTDA. CNPJ 20.706.163./0001-00, e a propriedade do sistema é, e será a qualquer tempo, da CROSSSYSTEM.</p>
                    <p>2.2 O Usuário concorda que o uso da PALLUA não significa, nem significará, aquisição de qualquer direito sobre o mesmo, não podendo explorá-lo comercialmente ou comercializá-lo.</p>
                    <p>2.3 O Usuário declara estar ciente de que a PALLUA contém informações confidenciais protegidas pela Lei de Proteção sobre Propriedade Intelectual e por direitos autorais.</p>
                    <br /><br />

                    <p>3. Serviço</p>
                    <p>3.1 Para utilização da PALLUA solicitamos os dados de identificação do Usuário para fins legais.</p>
                    <p>3.2 A PALLUA é comercializado aos clientes a partir de um modelo de cobrança de tarifas por captação financeira confirmada nos projetos gerenciados. Os valores das tarifas e os prazos são divulgados no site https://www.pallua.com.br e podem ser alterados a qualquer momento. A CROSSSYSTEM se compromete a informar previamente qualquer alteração aos usuários. As tarifas são previamente fixadas.</p>
                    <p>3.3 É reservado à CROSSSYSTEM o direito de modificar a PALLUA, acrescentar ou excluir funcionalidades, acrescentar serviços, alterar layout, etc, por qualquer motivo e sem aviso prévio, sem que isso implique qualquer responsabilidade em relação ao Usuário ou a terceiros.</p>
                    <p>3.4 Os dados dos Usuários da PALLUA são armazenados em servidores, que seguem políticas e normas de segurança da informação de primeiro nível, realizando cópias de segurança dos dados (backups) periodicamente e preparados para recuperá-los em caso de falhas.</p>
                    <p>3.5 A CROSSSYSTEM também realiza diariamente cópias de segurança dos dados (backups) de todos os Usuários da PALLUA e também está preparada para recuperá-los em caso de falhas. Essa redundância de armazenamento representa nosso segundo nível de segurança dos dados da PALLUA.</p>
                    <p>3.6 As informações inseridas na PALLUA são de total responsabilidade do Usuário.</p>
                    <p>3. Propriedade3.7 O Usuário se compromete a utilizar a PALLUA em conformidade com lei e regulamento local, estadual e nacional.</p>
                    <p>3.8 O Usuário se compromete a não utilizar a PALLUA para meios ilícitos ou que prejudique interesse de terceiros, respeitando a ética, a moral e os bons costumes.</p>
                    <p>3.9 Para acessar sua conta da PALLUA, o Usuário compromete-se a utilizar uma senha forte (difícil de ser descoberta) e não divulgá-la para outras pessoas, sendo responsável por sua confidencialidade.</p>
                    <p>3.10 O Usuário declara expressamente ser responsável por sua conta na PALLUA e aceita voluntariamente que o uso do software ocorra sob sua única e exclusiva responsabilidade.</p>
                    <p>3.11 Por ser um portal que permite o cadastramento de informações sobre projetos, a CROSSSYSTEM analisa e avalia a integridade de cada projeto publicado, podendo ser bloqueado caso seja identificado (i) informações falsas ou errôneas a respeito de projeto divulgadas no Website; (ii) problemas relativos à administração destes projetos, incluindo, mas não se limitando a atrasos, falta de qualidade, disparidades entre o projeto e o anúncio, projetos cadastrados que não correspondam às expectativas dos participantes.</p>
                    <p>3.12 Para cancelamento da conta, o proprietário da mesma deverá realizar a solicitação através do email relacionamento@pallua.com.br.</p>
                    <p>3.13 Para cancelamento de um projeto, a Organização deverá acessar o painel administrativo, com seu usuário e senha, e realizar a inativação do Projeto.</p>
                    <br /><br />

                    <p>4. Devolução</p>
                    <p>É possível realizar a devolução do valor doado no prazo máximo de 60 dias, enviado a solicitação para o email relacionamento@pallua.com.br.</p>
                    <p>4.1 Boleto bancário</p>
                    <p>Para receitas realizadas com boleto bancário, o doador deve fornecer os seguintes dados de quem realizou o pagamento:</p>
                    <p>- Nome completo;</p>
                    <p>- Número do CPF;</p>
                    <p>- Nome do banco;</p>
                    <p>- Número da conta bancária;</p>
                    <p>- Número da agência;</p>
                    <p>- Valor a ser devolvido;</p>
                    <p>Depois, é só esperar até 10 dias úteis para receber a restituição na sua conta corrente.</p>
                    <p>4.2 Cartão de crédito</p>
                    <p>para receitas realizadas com cartão de crédito, solicitaremos o estorno do débito à administradora do seu cartão. O prazo para o estorno das parcelas poderá ocorrer em até 2 faturas após o pedido do estorno. Lembre-se que esse procedimento é de responsabilidade da administradora do cartão. Deverá informar os seguintes dados:</p>
                    <p>- Nome completo;</p>
                    <p>- Número do CPF;</p>
                    <p>- Valor a ser estornado;</p>
                    <br /><br />

                    <p>5. Garantias e Responsabilidade</p>
                    <p>5.1 O uso da PALLUA é fornecido sem garantia implícita ou expressa quanto a erros, perda de informações ou qualquer outro evento causado pela má utilização por parte do Usuário.</p>
                    <p>5.2 A CROSSSYSTEM não garante que a PALLUA atenda as necessidades particulares do Usuário.</p>
                    <p>5.3 O Usuário declara estar ciente que a CROSSSYSTEM não tem, nem terá, qualquer responsabilidade, perante ao Usuário ou a terceiros, pelos prejuízos, danos patrimoniais ou morais de qualquer natureza resultante da má utilização da PALLUA.</p>
                    <p>5.4 Quando for solicitada transferência bancária de valores pelo usuário, a CROSSSYSTEM se responsabiliza e se compromete a realizar a mesma na conta informada pelo usuário em até 3 dias úteis, respeitando os valores solicitados e o saldo da conta do usuário, que se refere aos valores recebidos e compensados das receitas em projetos, já descontadas as tarifas cobradas pelo serviço, descritas no item 3.2 deste termo. O prazo de compensação das doações ocorre da seguinte forma:</p>
                    <p>5.4.1 Boleto: 1 dia após a notificação de pagamento enviado pelo banco. O Saldo ficará disponível na plataforma para resgate em até 1 dia útil após a compensação;</p>
                    <p>5.4.2 Cartão de Crédito: compensação conforme parcelamento (Ex: 30, 60, 90, 120 dias, etc). Saldo ficará disponível na plataforma para resgate em até 1 dia útil após a compensação;
                    </p>
                    <br /><br />

                    <p>6. Foro</p>
                    <p>6.1 As Partes desenvolverão todos os esforços no sentido de resolver, amigavelmente, todas as controvérsias ou divergências que, porventura, forem decorrentes deste Termo.</p>
                    <p>6.2 Não obstante o disposto no item anterior, o Usuário, ao aceitar este Termo, concorda em eleger o foro da comarca do Rio de Janeiro, no Estado do Rio de Janeiro, como competente para dirimir qualquer dúvida ou controvérsia porventura oriunda deste Termo e/ou da utilização do Sistema e, expressamente, renuncia a qualquer outro.</p>
                    <br /><br />

                    <p>7 . Disposições Finais</p>
                    <p>7.1 O Usuário concorda em receber, via e-mail, informativos do PALLUA e da CROSSSYSTEM.</p>
                    <p>7.2 Os termos e condições poderão ser atualizados e modificados a qualquer tempo, mediante notificação prévia. Estando o Usuário de acordo, será feito um termo aditivo de comum acordo entre as partes.</p>
                    <p>7.3 O Usuário aceita que a CROSSSYSTEM poderá, ao seu critério, alterar a prestação de serviços da PALLUA, interromper temporária ou definitivamente, mediante aviso prévio ao Usuário.</p>
                    <p>7.4 A violação de qualquer condição estabelecida neste termo ensejará a imediata exclusão do Usuário do cadastro da PALLUA, ficando a partir de então suspenso qualquer acesso do Usuário às áreas restritas do Website, sem prejuízo do direito da CROSSSYSTEM e dos terceiros prejudicados de exigirem a reparação dos danos eventualmente causados independentemente de prévia notificação formal.</p>
                    <p>7.5 O Usuário será o único, direto e exclusivo responsável por todos os danos, infrações, descumprimento legais e atos de qualquer natureza, que venha a causar a terceiros ou outros quando da utilização do sistema objeto deste instrumento. Na hipótese da CROSSSYSTEM vir a ser demandada em juízo por dano ocasionado pelo Usuário, ser-lhe-á lícito alegar sua ilegitimidade passiva, nomeá-lo à autoria, denunciá-lo à lide etc., enfim, praticar todos os atos necessários para exercer seu direito de regresso, servindo o presente instrumento como título executivo extrajudicial de todos e quaisquer valores eventualmente despendidos pela CROSSSYSTEM para fazer frente a indenizações, despesas judiciais etc., sendo que estes deverão ser atualizados na forma da lei.</p>
                    <p>7.6 O Usuário declara que leu e concordou com todos os termos acima citados.</p>
                </div>
            </main>

            <Footer />
        </div>
    }
}