import { Button } from "primereact/button";
import { Component } from "react";
import Footer from "./components/Footer";
import Header from "./components/Header";

export default class TaxasServicos extends Component {
    render() {
        return <div>
            <Header />

            <main style={{ backgroundColor: '#FFBB05', padding: 50, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 50 }}>
                <h1 style={{ color: 'white' }}>Taxas e Serviços</h1>
                <div style={{ maxWidth: 1000, minWidth: '100%', backgroundColor: 'white', borderRadius: 15, border: '1px solid gray', color: 'gray', padding: 20, textAlign: 'center', display: "flex", gap: 30, justifyContent: "center", alignItems: 'center', flexWrap: 'wrap' }}>
                    <div style={{ width: 300, display: "flex", flexDirection: "column", gap: 5, alignItems: "center" }}>
                        <Button label={<i style={{ fontSize: 20 }} className="pi pi-bookmark"></i>} className="p-button-warning" style={{ padding: 10, borderRadius: 500 }} />
                        <h3>Checkout transparente</h3>
                        <h4 style={{ fontWeight: "normal" }}>Sem burocracia no cadastro. 45 segundos é o tempo médio para uma pessoa realizar a transação financeira.</h4>
                    </div>

                    <div style={{ width: 300, display: "flex", flexDirection: "column", gap: 5, alignItems: "center" }}>
                        <Button label={<i style={{ fontSize: 20 }} className="pi pi-bookmark"></i>} className="p-button-warning" style={{ padding: 10, borderRadius: 500 }} />
                        <h3>WhatsApp</h3>
                        <h4 style={{ fontWeight: "normal" }}>Totalmente compativel com compartilhamento no WhatsApp, fazendo sua campanha viralizar.</h4>
                    </div>

                    <div style={{ width: 300, display: "flex", flexDirection: "column", gap: 5, alignItems: "center" }}>
                        <Button label={<i style={{ fontSize: 20 }} className="pi pi-bookmark"></i>} className="p-button-warning" style={{ padding: 10, borderRadius: 500 }} />
                        <h3>Formas de Recebimento</h3>
                        <h4 style={{ fontWeight: "normal" }}>Seu projeto pode receber com boleto bancário registrado, cartão de crédito nacional e internacional.</h4>
                    </div>

                    <div style={{ width: 300, display: "flex", flexDirection: "column", gap: 5, alignItems: "center" }}>
                        <Button label={<i style={{ fontSize: 20 }} className="pi pi-bookmark"></i>} className="p-button-warning" style={{ padding: 10, borderRadius: 500 }} />
                        <h3>Moedas</h3>
                        <h4 style={{ fontWeight: "normal" }}>Recebemos recursos financeiros em Real (R$), Dolar (US$) e Euro (€).</h4>
                    </div>

                    <div style={{ width: 300, display: "flex", flexDirection: "column", gap: 5, alignItems: "center" }}>
                        <Button label={<i style={{ fontSize: 20 }} className="pi pi-bookmark"></i>} className="p-button-warning" style={{ padding: 10, borderRadius: 500 }} />
                        <h3>Idiomas</h3>
                        <h4 style={{ fontWeight: "normal" }}>Português e Inglês são as traduções configuradas para facilitar a captação financeira.</h4>
                    </div>

                    <div style={{ width: 300, display: "flex", flexDirection: "column", gap: 5, alignItems: "center" }}>
                        <Button label={<i style={{ fontSize: 20 }} className="pi pi-bookmark"></i>} className="p-button-warning" style={{ padding: 10, borderRadius: 500 }} />
                        <h3>Taxa no Boleto</h3>
                        <h4 style={{ fontWeight: "normal" }}>Transação de boleto é cobrado apenas valor fixo.
                            Boleto gerado: R$ 0,00
                            Boleto pago R$5,30</h4>
                    </div>

                    <div style={{ width: 300, display: "flex", flexDirection: "column", gap: 5, alignItems: "center" }}>
                        <Button label={<i style={{ fontSize: 20 }} className="pi pi-bookmark"></i>} className="p-button-warning" style={{ padding: 10, borderRadius: 500 }} />
                        <h3>Taxa no Cartão de Crédito</h3>
                        <h4 style={{ fontWeight: "normal" }}>Transação de cartão de crédito é cobrado apenas percentual.
                            Transação recusada: 0.00%
                            Transação aceita 7,2%</h4>
                    </div>

                    <div style={{ width: 300, display: "flex", flexDirection: "column", gap: 5, alignItems: "center" }}>
                        <Button label={<i style={{ fontSize: 20 }} className="pi pi-bookmark"></i>} className="p-button-warning" style={{ padding: 10, borderRadius: 500 }} />
                        <h3>Parcelamento</h3>
                        <h4 style={{ fontWeight: "normal" }}>É possível parcelamento em até 12x utilizando a mesma taxa. Esse recurso é utilizado em inscrições em eventos.</h4>
                    </div>

                    <div style={{ width: 300, display: "flex", flexDirection: "column", gap: 5, alignItems: "center" }}>
                        <Button label={<i style={{ fontSize: 20 }} className="pi pi-bookmark"></i>} className="p-button-warning" style={{ padding: 10, borderRadius: 500 }} />
                        <h3>Segurança</h3>
                        <h4 style={{ fontWeight: "normal" }}>Todos os cadastros são analisados para garantir a legitimidade na captação dos recursos.
                            Cadastros identificados como farsa são bloqueados.</h4>
                    </div>


                    <div style={{ width: 300, display: "flex", flexDirection: "column", gap: 5, alignItems: "center" }}>
                        <Button label={<i style={{ fontSize: 20 }} className="pi pi-bookmark"></i>} className="p-button-warning" style={{ padding: 10, borderRadius: 500 }} />
                        <h3>Tranferência</h3>
                        <h4 style={{ fontWeight: "normal" }}>A tranferência é realizada on-line dentro da plataforma no momento que você desejar.</h4>
                    </div>

                    <div style={{ width: 300, display: "flex", flexDirection: "column", gap: 5, alignItems: "center" }}>
                        <Button label={<i style={{ fontSize: 20 }} className="pi pi-bookmark"></i>} className="p-button-warning" style={{ padding: 10, borderRadius: 500 }} />
                        <h3>Tempo da tranferência</h3>
                        <h4 style={{ fontWeight: "normal" }}>Tranferência realizada nos dias úteis até às 10:30Hs estará na sua conta bancária no mesmo dia. Finais de semana e feriados, será agendado para o próximo dia útil.</h4>
                    </div>

                    <div style={{ width: 300, display: "flex", flexDirection: "column", gap: 5, alignItems: "center" }}>
                        <Button label={<i style={{ fontSize: 20 }} className="pi pi-bookmark"></i>} className="p-button-warning" style={{ padding: 10, borderRadius: 500 }} />
                        <h3>Taxa de tranferência</h3>
                        <h4 style={{ fontWeight: "normal" }}>O processo criar uma tranferência on-line do Bradesco para sua conta bancária.
                            Bradesco para Bradesco: R$ 0,00
                            Bradesco para outros bancos(TED): R$ 3,67</h4>
                    </div>

                    <div style={{ width: 300, display: "flex", flexDirection: "column", gap: 5, alignItems: "center" }}>
                        <Button label={<i style={{ fontSize: 20 }} className="pi pi-bookmark"></i>} className="p-button-warning" style={{ padding: 10, borderRadius: 500 }} />
                        <h3>Liquidez do boleto</h3>
                        <h4 style={{ fontWeight: "normal" }}>Recebíveis de boleto estará disponível para tranferência 1 dia útil após notificação de pagamento do banco.</h4>
                    </div>

                    <div style={{ width: 300, display: "flex", flexDirection: "column", gap: 5, alignItems: "center" }}>
                        <Button label={<i style={{ fontSize: 20 }} className="pi pi-bookmark"></i>} className="p-button-warning" style={{ padding: 10, borderRadius: 500 }} />
                        <h3>Liquidez do cartão de crédito</h3>
                        <h4 style={{ fontWeight: "normal" }}>Recebíveis de cartão de crédito estará disponível para tranferência 30 dias após a transação.</h4>
                    </div>

                    <div style={{ width: 300, display: "flex", flexDirection: "column", gap: 5, alignItems: "center" }}>
                        <Button label={<i style={{ fontSize: 20 }} className="pi pi-bookmark"></i>} className="p-button-warning" style={{ padding: 10, borderRadius: 500 }} />
                        <h3>Antecipação</h3>
                        <h4 style={{ fontWeight: "normal" }}>Você pode ter acesso ao recurso disponível no cartão de crédito no próximo dia útil, para isso é necessário verificar a taxa de antecipação através de simulação.</h4>
                    </div>
                </div>
            </main>

            <Footer />
        </div>
    }
}