import { Component, createRef } from "react";
import Header from "./components/Header";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import ProjetoDetalhe from "./ProjetoDetalhe";
import Item from 'lirida-back-service/Servicos/Item/Item';
import ItemService from "lirida-back-service/Servicos/Item/ItemService";
import Constante from "../../util/Constante";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import Util from "../../util/Util";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Steps } from 'primereact/steps';
import { Dropdown } from "primereact/dropdown";
import ItemCategoriaService from "lirida-back-service/Servicos/ItemCategoria/ItemCategoriaService";
import ItemCategoria from "lirida-back-service/Servicos/ItemCategoria/ItemCategoria";
import ItemRelacionamento from "lirida-back-service/Servicos/ItemRelacionamento/ItemRelacionamento";
import ItemTag from "lirida-back-service/Servicos/ItemTag/ItemTag";
import { InputNumber } from "primereact/inputnumber";
import { Checkbox } from "primereact/checkbox";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";

export default class Projeto extends Component {
    toast = createRef();
    constante = new Constante();
    util = new Util();
    itemService = new ItemService(this.constante.token, this.constante.url_base);
    itemCategoriaService = new ItemCategoriaService(this.constante.token, this.constante.url_base);
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base);

    state = {
        pessoaUsuario: new Pessoa(),
        conteudo: '',
        listaItem: [],
        item: new Item(),
        tela: "1",
        urlBase: '',

        itemCategoria: new ItemCategoria(),
        itemTag: new ItemTag(),
        listaItemCategoria: [],
        vModalNovo: false,
        indexStep: 0,
        proximoCarregando: false,
        imagemPreview: '',

        inputError: {
            nome: false,
            descricao: false,
            imagem: false,
            categoria: false,
            tagTitulo: false,
            tagValor: false,
        }
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        const pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
        const urlBase = await this.util.buscarUrlBaseArquivo();
        const parametros = this.util.buscarParametros();

        console.log(pessoaUsuario)

        await this.listar(pessoaUsuario);
        this.listarItemCategoria();


        this.setState({
            pessoaUsuario: pessoaUsuario,
            urlBase: urlBase,
        });
    }

    async listar(pPessoa) {
        const listaPesquisa = [];
        let listaItem = [];
        let pessoaUsuario;

        if (pPessoa) {
            pessoaUsuario = pPessoa;
        } else {
            pessoaUsuario = this.state.pessoaUsuario;
        }

        this.setState({
            listaItem: listaItem,
        })

        let pesquisa = new Pesquisa();
        pesquisa.campo = "item.seq_usuario";
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = "";
        pesquisa.tipo = "TEXTO";

        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = "item.nome";
        pesquisa.conteudo = this.state.conteudo;
        pesquisa.operacao = "AND";
        pesquisa.tipo = "TEXTO";

        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = "item_relacionamento.seq_tipo_relacionamento";
        pesquisa.conteudo = this.constante.seqTipoRelacionamentoItemDonoProjeto;
        pesquisa.operacao = "AND";
        pesquisa.tipo = "TEXTO";

        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = "item_relacionamento.seq_pessoa_relacionada";
        pesquisa.conteudo = pessoaUsuario._seqPessoa;
        pesquisa.operacao = "AND";
        pesquisa.tipo = "TEXTO";

        listaPesquisa.push(pesquisa);

        const retorno = await this.itemService.listarComFiltro(listaPesquisa);
        console.log(retorno);
        listaItem = retorno.objeto;

        let vModalNovo = false;

        if(!listaItem.length) {
            vModalNovo = true;
        }

        this.setState({ 
            listaItem: listaItem,
            vModalNovo: vModalNovo
         });
    }

    selecionar(pItem) {
        console.log(pItem)
        this.setState({
            item: pItem,
            tela: "2"
        });
    }

    async novoProjeto() {
        const inputError = this.state.inputError;
        const listaChaveInputError = Object.keys(inputError);

        for (let i = 0; i < listaChaveInputError.length; i++) {
            inputError[listaChaveInputError[i]] = false;
        }

        if (this.state.indexStep === 0) {
            if (!this.state.item.nome) {
                inputError.nome = true;
            }
            if (!this.state.item.descricao) {
                inputError.descricao = true;
            }
            if (!this.state.itemCategoria._seqItemCategoria) {
                inputError.categoria = true;
            }
        }

        if (this.state.indexStep === 1) {
            if (!this.state.item.imagem) {
                inputError.imagem = true;
            }
        }

        if (this.state.indexStep === 2) {
            if (!this.state.itemTag.tag) {
                inputError.tagTitulo = true;
            }

            console.log(this.state.itemTag)
            console.log(inputError)
        }

        let inputOK = true;

        for (let i = 0; i < listaChaveInputError.length; i++) {
            if (inputError[listaChaveInputError[i]]) {
                inputOK = false;
            }
        }

        this.setState({ inputError: inputError });

        if (!inputOK) {
            return;
        }

        if (this.state.indexStep < 2) {
            this.setState({ indexStep: this.state.indexStep + 1 });
            return;
        }

        this.setState({ proximoCarregando: true });

        const itemCategoria = this.state.itemCategoria;
        const itemTag = this.state.itemTag;
        const pessoaUsuario = this.state.pessoaUsuario;
        let item = this.state.item;

        item.seqUsuario = this.constante.seqUsuario;
        item.seqItemCategoria = itemCategoria._seqItemCategoria;
        item.situacao = "ATIVO";
        item.codigo = this.state.listaItem.length + 1;

        const retornoItem = await this.itemService.salvar(item);
        item = retornoItem.objeto;

        const itemRelacionamento = new ItemRelacionamento();

        itemRelacionamento.seqUsuario = this.constante.seqUsuario;
        itemRelacionamento.seqItem = item._seqItem;
        itemRelacionamento.seqTipoRelacionamento = this.constante.seqTipoRelacionamentoItemDonoProjeto;
        itemRelacionamento.seqPessoaRelacionada = this.state.pessoaUsuario._seqPessoa;

        await this.itemService.salvarRelacionamento(itemRelacionamento);

        itemTag.seqItem = item._seqItem;
        itemTag.tags0 = 1;

        if(!itemTag.valor) {
            itemTag.valor = 0;
            itemTag.opc = "true";
        }

        await this.itemService.salvarTag(itemTag);

        pessoaUsuario.tags0 = 'S';

        await this.salvarPessoa(pessoaUsuario);

        this.toast.current.show([
            { severity: 'success', summary: 'Projeto armazenado com sucesso!' },
        ]);

        this.setState({
            proximoCarregando: false,
            item: item,
            tela: "2",
            vModalNovo: false,
            indexStep: 0,
        })
    }

    async salvarPessoa(pPessoa) {
        const retornoPessoa = await this.pessoaService.salvar(pPessoa);
        const pessoa = retornoPessoa.objeto;
        
        this.util.salvarLocalStorage("PESSOA_USUARIO", pessoa);

        return pessoa;
    }

    async selecionarArquivo(pArquivo) {
        this.setState({ proximoCarregando: true });
        const base64 = await this.util.pegarBase64(pArquivo);

        const objetoUrl = URL.createObjectURL(pArquivo)

        this.setState({
            item: {
                ...this.state.item,
                imagem: base64
            },
            imagemPreview: objetoUrl,
            proximoCarregando: false,
        });
    }

    async listarItemCategoria() {
        const retorno = await this.itemCategoriaService.listar(this.constante.seqUsuario);
        const listaItemCategoria = retorno.objeto;

        console.log(listaItemCategoria)

        this.setState({
            listaItemCategoria: listaItemCategoria,
        });
    }

    render() {
        return <div>
            <Dialog
                header="Novo projeto"
                visible={this.state.vModalNovo}
                style={{ width: 500 }}
                onHide={() => this.setState({ vModalNovo: false, item: new Item() })}
            >
                <Steps
                    model={[{ label: "Projeto" }, { label: "Foto" }, { label: "Doação" }]}
                    activeIndex={this.state.indexStep} />

                <form
                    onSubmit={e => { e.preventDefault(); this.novoProjeto() }}
                    style={{ padding: 20, display: "flex", flexDirection: "column", gap: 10 }}>
                    {this.state.indexStep === 0 &&
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 10, padding: '20px 0' }}>
                            <h2>Conte-nos sobre o seu projeto</h2>
                            <div>
                                <label>Nome do projeto</label><br />
                                <InputText
                                    onChange={e => this.setState({
                                        item: {
                                            ...this.state.item,
                                            nome: e.target.value
                                        }
                                    })}
                                    value={this.state.item.nome}
                                    style={{ width: "100%", borderColor: this.state.inputError.nome && 'var(--red-600)' }} />
                                {this.state.inputError.nome && <small style={{ color: 'var(--red-600)' }}>Nome inválido</small>}
                            </div>
                            <div>
                                <label>Descreva seu projeto</label><br />
                                <InputTextarea
                                    autoResize
                                    onChange={e => this.setState({
                                        item: {
                                            ...this.state.item,
                                            descricao: e.target.value
                                        }
                                    })}
                                    value={this.state.item.descricao}
                                    style={{ width: "100%", borderColor: this.state.inputError.descricao && 'var(--red-600)' }} />
                                {this.state.inputError.descricao && <small style={{ color: 'var(--red-600)' }}>Descrição inválido</small>}
                            </div>
                            <div>
                                <label>Onde se encontra o seu projeto?</label><br />
                                <Dropdown
                                    placeholder="Selecione uma região"
                                    options={this.state.listaItemCategoria}
                                    optionLabel="nome"
                                    onChange={e => this.setState({ itemCategoria: e.value })}
                                    value={this.state.itemCategoria}
                                    style={{ width: "100%", borderColor: this.state.inputError.categoria && 'var(--red-600)' }} />
                                {this.state.inputError.categoria && <small style={{ color: 'var(--red-600)' }}>Região inválida</small>}
                            </div>
                        </div>
                    }

                    {this.state.indexStep === 1 &&
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 10, padding: '20px 0' }}>
                            <h2>Escolha uma foto para o seu projeto</h2>
                            {this.state.imagemPreview &&
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <img src={this.state.imagemPreview} style={{ height: 100, objectFit: 'contain' }} alt=""/>
                                </div>
                            }
                            <div>
                                <InputText
                                    type="file"
                                    onChange={e => this.selecionarArquivo(e.target.files[0])}
                                    style={{ width: "100%", borderColor: this.state.inputError.imagem && 'var(--red-600)' }} />
                                {this.state.inputError.imagem && <small style={{ color: 'var(--red-600)' }}>Imagem inválida</small>}
                            </div>
                        </div>
                    }

                    {this.state.indexStep === 2 &&
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 10, padding: '20px 0' }}>
                            <h2>Crie uma opção de pagamento</h2>
                            <div>
                                <label>Titulo da doação</label><br />
                                <InputText
                                    onChange={e => this.setState({
                                        itemTag: {
                                            ...this.state.itemTag,
                                            tag: e.target.value,
                                        }
                                    })}
                                    value={this.state.itemTag.tag}
                                    style={{ width: "100%", borderColor: this.state.inputError.tagTitulo && 'var(--red-600)' }} />
                                {this.state.inputError.tagTitulo && <small style={{ color: 'var(--red-600)' }}>Título inválido</small>}
                            </div>
                            <div>
                                <label>Valor da doação</label><br />
                                <InputNumber
                                    mode="currency"
                                    currency="BRL"
                                    locale="pt-BR"
                                    type="tel"
                                    value={this.state.itemTag.valor}
                                    onChange={e => this.setState({
                                        itemTag: {
                                            ...this.state.itemTag,
                                            valor: e.value
                                        }
                                    })}
                                    style={{ width: "100%", }}
                                    inputStyle={{ borderColor: this.state.inputError.tagValor && 'var(--red-600)' }} />
                                {this.state.inputError.tagValor && <small style={{ color: 'var(--red-600)' }}>Valor inválido</small>}
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: 10, }}>
                                <Checkbox
                                    onChange={e => this.setState({
                                        itemTag: {
                                            ...this.state.itemTag,
                                            opc: e.target.checked || null
                                        }
                                    })}
                                    checked={this.state.itemTag.opc} />
                                <label>Permitir doador alterar o valor?</label>
                            </div>
                        </div>
                    }
                    <div style={{ display: "flex", justifyContent: this.state.indexStep > 0 ? "space-between" : "right" }}>
                        {this.state.indexStep > 0 &&
                            <Button label="Voltar" type="button" className="p-button-link" onClick={() => this.setState({ indexStep: this.state.indexStep - 1 })} />
                        }
                        <Button label="Próximo" type="submit" loading={this.state.proximoCarregando} />
                    </div>
                </form>
            </Dialog>

            <Header />

            <div style={{ width: '95%', margin: '20px auto' }}>
                <Toast ref={this.toast} />
            </div>

            {this.state.tela === "2" ?
                <ProjetoDetalhe
                    item={this.state.item}
                    listar={() => this.listar()}
                    setState={e => this.setState(e)}
                    toast={this.toast} />
                :
                <main style={{ width: '95%', margin: '20px auto' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: '1px solid lightgray', padding: 10, borderRadius: 3 }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 20, margin: '20px 0' }}>
                            <div>
                                <h1 style={{ fontSize: 22 }}>Projetos</h1>
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 10, }}>
                            <InputText placeholder="Pesquisar" value={this.state.conteudo} onChange={e => this.setState({ conteudo: e.target.value })} />
                            <Button label="Buscar" className="p-button-raised" onClick={() => this.listar()} />
                            <Button label="Novo" className="p-button-raised" onClick={() => this.setState({ vModalNovo: true })} />
                        </div>
                    </div>

                    <DataTable
                        style={{ marginTop: 20 }}
                        selectionMode="single"
                        showGridlines
                        removableSort
                        value={this.state.listaItem}
                        onSelectionChange={e => this.selecionar(e.value)}
                        paginator
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10, 20, 50]}>
                        {/* <Column field="_seqItem" header="Id" sortable></Column> */}
                        <Column field="imagem" header="Imagem" body={(item) => {
                            return <img src={this.state.urlBase + item.imagem} alt={item.nome} style={{ width: 100, maxHeight: 100, objectFit: 'contain', backgroundColor: "lightgray" }} />
                        }} sortable></Column>
                        <Column field="nome" header="Nome" sortable></Column>
                        <Column field="descricao" header="Descrição" sortable></Column>
                        <Column field="situacao" header="Situação" sortable></Column>
                    </DataTable>
                </main>
            }
        </div>
    }
}