import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import { Button } from "primereact/button";
import { Captcha } from "primereact/captcha";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Messages } from "primereact/messages";
import { Component, createRef } from "react";
import { Link } from "react-router-dom";
import Constante from "../../util/Constante";
import Util from "../../util/Util";
import Footer from "./components/Footer";
import Header from "./components/Header";

export default class Cadastro extends Component {
    util = new Util();
    constante = new Constante();
    mensagensCadastro = createRef();
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base);

    state = {
        conteudo: '',
        pessoa: new Pessoa(),
        carregando: false,
        liberarAcesso: {
            dashboard: false,
        },
        inputCadastroError: {
            nome: false,
            email: false,
            telefone: false,
            termoLido: false,
            captcha: false,
        },
        termoLido: false,
        captchaOK: false,
    }

    async cadastrar(e) {
        let pessoa = this.state.pessoa;
        const inputCadastroError = this.state.inputCadastroError;
        const listaChaveInputCadastroError = Object.keys(inputCadastroError);
        const nome = e.target[0].value;
        const email = e.target[1].value;
        const termoLido = e.target[3].checked;

        for (let i = 0; i < listaChaveInputCadastroError.length; i++) {
            inputCadastroError[listaChaveInputCadastroError[i]] = false;
        }

        const retornoValidacaoEmail = this.util.validarEmail(email);
        const retornoValidacaoTelefone = this.util.validarTelefone(this.state.pessoa.telefonePlataformaLirida);

        if (!nome) {
            inputCadastroError.nome = true;
        }

        if (!retornoValidacaoEmail) {
            inputCadastroError.email = true;
        }

        if (!retornoValidacaoTelefone) {
            inputCadastroError.telefone = true;
        }

        if (!termoLido) {
            inputCadastroError.termoLido = true;
        }

        if (!this.state.captchaOK) {
            inputCadastroError.captcha = true;
        }

        let inputOK = true;

        for (let i = 0; i < listaChaveInputCadastroError.length; i++) {
            if (inputCadastroError[listaChaveInputCadastroError[i]]) {
                inputOK = false;
            }
        }

        this.setState({ inputCadastroError: inputCadastroError });

        if (!inputOK) {
            return;
        }

        this.setState({ carregando: true });

        this.mensagensCadastro.current.clear();

        pessoa.nome = nome;
        pessoa.emailPlataformaLirida = email;
        pessoa.telefonePlataformaLirida = this.state.pessoa.telefonePlataformaLirida;
        pessoa.seqUsuario = this.constante.seqUsuario;
        pessoa.tipoDocumento = "CPF";
        pessoa.seqPessoaPerfil = this.constante.seqPessoaPerfilAgencia;
        pessoa.taxaBoleto = this.constante.taxaBoleto;
        pessoa.taxaCredito = this.constante.taxaCredito;
        pessoa.taxaPix = this.constante.taxaPix;
        pessoa.tipoTaxaBoleto = this.constante.tipoTaxaBoleto;
        pessoa.tipoTaxaCredito = this.constante.tipoTaxaCredito;
        pessoa.tipoTaxaPix = this.constante.tipoTaxaPix;

        console.log(pessoa);

        const listaPesquisa = [];

        listaPesquisa.push(
            this.util.retornaPesquisa(
                "pessoa.seq_usuario",
                this.constante.seqUsuario,
                "",
                "TEXTO"
            )
        )

        listaPesquisa.push(
            this.util.retornaPesquisa(
                "pessoa.email_plataforma_lirida",
                email,
                "AND",
                "TEXTO"
            )
        )

        listaPesquisa.push(
            this.util.retornaPesquisa(
                "pessoa.seq_pessoa_perfil",
                this.constante.seqPessoaPerfilAgencia,
                "AND",
                "TEXTO"
            )
        )

        const retornoListaPessoa = await this.pessoaService.listarComFiltro(listaPesquisa);

        console.log(retornoListaPessoa)

        if (!retornoListaPessoa.objeto[0]) {
            const retornoPessoa = await this.pessoaService.salvar(pessoa);
            pessoa = retornoPessoa.objeto;

            console.log(pessoa);
        } else {
            pessoa = retornoListaPessoa.objeto[0];
        }

        console.log(pessoa);

        const apiEmail = {
            seqEmail: this.constante.seqEmailAcesso,
            email: pessoa.emailPlataformaLirida,
            parametro: {
                nome: pessoa.nome,
                email: pessoa.emailPlataformaLirida,
                senha: pessoa.senha
            }
        }

        this.util.enviarEmail(apiEmail);

        this.mensagensCadastro.current.show([
            { severity: 'success', summary: 'Cadastro concluído com sucesso! Enviamos as credênciais de acesso para o seu email!', sticky: true },
        ]);

        e.target.reset();

        window.scrollTo(0, 0);

        this.setState({ carregando: false, pessoa: new Pessoa() });
    }


    render() {
        return <div>
            <Header />

            <main className="h-container-amarelo">
                <Messages ref={this.mensagensCadastro} />
                <h1 style={{ color: 'white' }}>Criar conta</h1>
                <form
                    className="h-form"
                    onSubmit={e => { e.preventDefault(); this.cadastrar(e) }}>
                    <div>
                        <label>Nome</label><br />
                        <InputText
                            style={{ width: '100%', borderColor: this.state.inputCadastroError.nome && 'var(--red-600)' }} />
                        {this.state.inputCadastroError.nome && <small style={{ color: 'var(--red-600)' }}>Nome inválido</small>}
                    </div>
                    <div>
                        <label>Email</label><br />
                        <InputText
                            style={{ width: '100%', borderColor: this.state.inputCadastroError.email && 'var(--red-600)' }} />
                        {this.state.inputCadastroError.email && <small style={{ color: 'var(--red-600)' }}>Email inválido</small>}
                    </div>
                    <div>
                        <label>Telefone</label><br />
                        <InputText
                            onChange={e => this.setState({
                                pessoa: {
                                    ...this.state.pessoa,
                                    telefonePlataformaLirida: this.util.formatarTelefone(e.target.value)
                                }
                            })}
                            value={this.state.pessoa.telefonePlataformaLirida || ''}
                            style={{ width: '100%', borderColor: this.state.inputCadastroError.telefone && 'var(--red-600)' }} />
                        {this.state.inputCadastroError.telefone && <small style={{ color: 'var(--red-600)' }}>Telefone inválido</small>}
                    </div>

                    <div style={{ display: 'flex', gap: 10, alignItems: 'center', justifyContent: 'right' }}>
                        <Checkbox onChange={e => this.setState({ termoLido: e.checked })} checked={this.state.termoLido} style={{ outline: this.state.inputCadastroError.termoLido && '1px solid red', borderRadius: 5 }} />
                        <div>
                            <label>Li e aceito os <Link to="/termos_de_uso" target="_blank">termos de uso</Link></label><br />
                            {this.state.inputCadastroError.termoLido && <small style={{ color: 'var(--red-600)', marginLeft: 'auto' }}>Termo de uso não lido</small>}
                        </div>
                    </div>

                    <div style={{ margin: '0 auto' }}>
                        <Captcha language="pt-BR" siteKey="6LdLU_MhAAAAAPntkRvPaskZa4qClbDPVGXbaxET" onResponse={e => this.setState({ captchaOK: true })}></Captcha>
                        {this.state.inputCadastroError.captcha && <small style={{ color: 'var(--red-600)', marginLeft: 'auto' }}>reCaptcha não verificado</small>}
                    </div>

                    <div style={{ display: "flex", justifyContent: "right" }}>
                        <Button
                            label="Cadastrar"
                            type="submit"
                            className="p-button-warning"
                            loading={this.state.carregando} />
                    </div>
                </form>
            </main>

            <Footer />
        </div>
    }
}