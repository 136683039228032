import { Component, createRef } from "react";
import Footer from "./components/Footer";
import Header from "./components/Header";
import { InputText } from "primereact/inputtext";
import { Messages } from "primereact/messages";
import { Captcha } from 'primereact/captcha';
import { Button } from "primereact/button";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import Constante from "../../util/Constante";
import Util from "../../util/Util";
import { Navigate } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import { Toast } from "primereact/toast";

export default class Login extends Component {
    mensagens = createRef();
    toast = createRef();
    util = new Util();
    constante = new Constante();
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base);

    state = {
        carregando: false,
        inputLoginError: {
            email: false,
            senha: false,
            captcha: false,
        },
        liberarAcesso: {
            financeiro: false,
        },
        captchaOK: false,
        modalRecuperarSenhaVisivel: false,
        modalVersaoVisivel: true,
    }

    async validarAcesso(e) {
        const liberarAcesso = this.state.liberarAcesso;
        const inputLoginError = this.state.inputLoginError;
        const listaChaveInputLoginError = Object.keys(inputLoginError);

        const email = e.target[0].value;
        const senha = e.target[1].value;

        for (let i = 0; i < listaChaveInputLoginError.length; i++) {
            inputLoginError[listaChaveInputLoginError[i]] = false;
        }

        const retornoValidacaoEmail = this.util.validarEmail(email);

        if (!email) {
            inputLoginError.email = true;
        }

        if (!retornoValidacaoEmail) {
            inputLoginError.email = true;
        }

        if (!senha) {
            inputLoginError.senha = true;
        }

        if (!this.state.captchaOK) {
            inputLoginError.captcha = true;
        }

        let inputOK = true;

        for (let i = 0; i < listaChaveInputLoginError.length; i++) {
            if (inputLoginError[listaChaveInputLoginError[i]]) {
                inputOK = false;
            }
        }

        this.setState({ inputLoginError: inputLoginError });

        if (!inputOK) {
            return;
        }

        this.setState({ carregando: true });

        this.mensagens.current.clear();

        const listaPesquisa = [];

        listaPesquisa.push(
            this.util.retornaPesquisa(
                "pessoa.seq_usuario",
                this.constante.seqUsuario,
                "",
                "TEXTO"
            )
        )

        listaPesquisa.push(
            this.util.retornaPesquisa(
                "pessoa.email_plataforma_lirida",
                email,
                "AND",
                "TEXTO"
            )
        )

        listaPesquisa.push(
            this.util.retornaPesquisa(
                "pessoa.seq_pessoa_perfil",
                this.constante.seqPessoaPerfilAgencia,
                "AND",
                "TEXTO"
            )
        )

        const retornoListaPessoa = await this.pessoaService.listarComFiltro(listaPesquisa);
        let pessoa = retornoListaPessoa.objeto[0];

        if (!pessoa) {
            this.mensagens.current.show([
                { severity: 'error', summary: 'Usuário não encontrado', sticky: true },
            ]);
            this.setState({ carregando: false });
            return;
        }

        if (senha !== pessoa.senha) {
            this.mensagens.current.show([
                { severity: 'error', summary: 'Senha inválida', sticky: true },
            ]);
            this.setState({ carregando: false });
            return;
        }

        liberarAcesso.financeiro = true;

        this.util.salvarLocalStorage("PESSOA_USUARIO", pessoa);
        this.setState({ liberarAcesso: liberarAcesso });
    }

    async recuperarSenha(pEvento) {
        pEvento.preventDefault();
        let pessoa = new Pessoa();

        this.setState({
            inputErrorRecuperarSenha: {
                email: false
            }
        })

        const listaPesquisa = [];

        listaPesquisa.push(
            this.util.retornaPesquisa(
                "pessoa.seq_usuario",
                this.constante.seqUsuario,
                "",
                "TEXTO"
            )
        )

        listaPesquisa.push(
            this.util.retornaPesquisa(
                "pessoa.email_plataforma_lirida",
                pEvento.target[0].value,
                "AND",
                "TEXTO"
            )
        )

        listaPesquisa.push(
            this.util.retornaPesquisa(
                "pessoa.seq_pessoa_perfil",
                this.constante.seqPessoaPerfilAgencia,
                "AND",
                "TEXTO"
            )
        )

        const retornoListaPessoa = await this.pessoaService.listarComFiltro(listaPesquisa);

        console.log(retornoListaPessoa)

        if (!retornoListaPessoa.objeto[0]) {
            this.setState({
                inputErrorRecuperarSenha: {
                    email: true
                }
            });
            return;
        } else {
            pessoa = retornoListaPessoa.objeto[0];
        }

        console.log(pessoa);

        const apiEmail = {
            seqEmail: this.constante.seqEmailAcesso,
            email: pessoa.emailPlataformaLirida,
            parametro: {
                nome: pessoa.nome,
                email: pessoa.emailPlataformaLirida,
                senha: pessoa.senha
            }
        }

        this.util.enviarEmail(apiEmail);

        this.toast.current.show([
            { severity: 'success', summary: 'Enviamos as credênciais de acesso para o seu email!' },
        ]);

        this.setState({
            modalRecuperarSenhaVisivel: false
        })
    }

    render() {
        return <div>
            {this.state.liberarAcesso.financeiro && <Navigate to="/sistema/financeiro" />}
            <Header />

            <Toast ref={this.toast} />

            <Dialog
                header="Selecione uma versão"
                style={{ width: "95%", maxWidth: 700 }}
                visible={this.state.modalVersaoVisivel}
                onHide={() => this.setState({ modalVersaoVisivel: false })}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 20, }}>
                    <div style={{ width: "50%", display: "flex", flexDirection: "column", alignItems: "center", gap: 20 }}>
                        <p style={{ fontWeight: "bold", textAlign: "center" }}>Versão 1.0</p>

                        <p style={{textAlign: "center"}}>Para contas criadas antes do dia 16/11/2022</p>

                        <Button
                            label="Selecionar versão 1.0" />
                    </div>
                    <div style={{ width: "50%", display: "flex", flexDirection: "column", alignItems: "center", gap: 20 }}>
                        <p style={{ fontWeight: "bold", textAlign: "center" }}>Versão 2.0</p>

                        <p style={{textAlign: "center"}}>Para contas criadas após o dia 16/11/2022</p>

                        <Button
                            label="Selecionar versão 2.0"
                            onClick={() => this.setState({modalVersaoVisivel: false})} />
                    </div>
                </div>
            </Dialog>

            <Dialog
                header="Recuperar senha"
                style={{ width: "95%", maxWidth: 600 }}
                visible={this.state.modalRecuperarSenhaVisivel}
                onHide={() => this.setState({ modalRecuperarSenhaVisivel: false })}>
                <form
                    onSubmit={pEvento => this.recuperarSenha(pEvento)}>
                    <div>
                        <label>Email</label><br />
                        <InputText
                            style={{ width: "100%" }} />
                    </div>
                    <div style={{ marginTop: 10, display: "flex", justifyContent: 'right' }}>
                        <Button
                            type="submit"
                            label="Enviar" />
                    </div>
                </form>

            </Dialog>

            <main className="h-container-amarelo">
                <Messages ref={this.mensagens} />
                <h1 style={{ color: 'white' }}>Login</h1>
                <form
                    className="h-form"
                    onSubmit={e => { e.preventDefault(); this.validarAcesso(e) }}>
                    <div>
                        <label>Email</label><br />
                        <InputText
                            style={{ width: '100%', borderColor: this.state.inputLoginError.email && 'var(--red-600)' }} />
                        {this.state.inputLoginError.email && <small style={{ color: 'var(--red-600)' }}>Email inválido</small>}
                    </div>
                    <div>
                        <label>Senha</label><br />
                        <InputText
                            type="password"
                            style={{ width: '100%', borderColor: this.state.inputLoginError.senha && 'var(--red-600)' }} />
                        {this.state.inputLoginError.senha && <small style={{ color: 'var(--red-600)' }}>Senha inválida</small>}
                    </div>

                    <div style={{ display: "flex", justifyContent: "right" }}>
                        <Button
                            type="button"
                            label="Recuperar senha"
                            className="p-button-link"
                            onClick={() => this.setState({ modalRecuperarSenhaVisivel: true })} />
                    </div>

                    <div style={{ margin: '0 auto' }}>
                        <Captcha language="pt-BR" siteKey="6LdLU_MhAAAAAPntkRvPaskZa4qClbDPVGXbaxET" onResponse={e => this.setState({ captchaOK: true })}></Captcha>
                        {this.state.inputLoginError.captcha && <small style={{ color: 'var(--red-600)', marginLeft: 'auto' }}>reCaptcha não verificado</small>}
                    </div>

                    <div style={{ display: "flex", justifyContent: "right" }}>
                        <Button
                            label="Acessar"
                            type="submit"
                            className="p-button-warning"
                            loading={this.state.carregando} />
                    </div>
                </form>
            </main>

            <Footer />
        </div>
    }
}