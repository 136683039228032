export default class Constante {
    /* DESENVOLVIMENTO */
    // url_base = 'http://192.168.1.20:8080/lirida/api/';
    // linkProjeto = 'http://192.168.1.14:3000/checkout?id=';
    // linkComprovante = 'http://192.168.1.14:3000/comprovante?id=';

    /* PRODUÇÃO */ 
    url_base = 'https://pallua.com.br/lirida_novo/api/';
    linkProjeto = 'https://teste.pallua.com.br/checkout?id=';
    linkComprovante = 'https://teste.pallua.com.br/comprovante?id=';
    
    token = '91cbc49b-e0ef-4180-a0e5-8158abf36ab0';

    url_imagem_placeholder = 'https://www.unfe.org/wp-content/uploads/2019/04/SM-placeholder.png'

    /* PERIODO RECORRENCIA */
    seqPeriodoRecorrenciaMensal = "1a589bc2-898e-48d1-a6ee-0999d1d4586a";

    /* PERFIL PERFIL */
    seqPessoaPerfilAgencia = '969a2f16-776d-4b0c-ab35-4a9a5df486fe';
    seqPessoaPerfilDoador = 'd3c5df52-a266-441d-b006-43746a71a374';

    /* PESSOA CARACTERISTICA */
    // seqPessoaCaracteristicaBase = '1a0bd8b9-c954-416b-8a1a-a64c673a88ac';
    // seqPessoaCaracteristicaIexcombo = 'd2de7156-12e5-43d3-bff9-917f82c1d1c6';

    /* USUARIO */
    seqUsuario = "74b5c3d8-cf6a-4198-a6a9-27fbdf9ee657";

    /* EMAIL */
    seqEmailAcesso = '0ecc58ba-316b-48e1-a5ac-d97883e895f2';

    /* TABELA PREÇO */
    // seqTabelaPrecoTotem = '085f4583-c330-4c10-9058-d184c96071ef';
    // seqTabelaPrecoVendas = '938f3656-3f7f-44bc-aa88-3607552da07c';

    /* ITEM */
    seqTipoRelacionamentoItemDonoProjeto = "aac52b1a-a3ff-4f96-bc4b-73c7f163f9b2"

    /* TIPO RELACIONAMENTO */
    // seqTipoRelacionamentoFabricante = '79224fa3-53a4-4231-8d9e-e9ec812ac3df';
    // seqTipoRelacionamentoCliente = 'af729e07-16dd-4753-b686-21c2b3ada6b0';
    // seqTipoRelacionamentoTecnicoRejeitado = '59f0df28-447d-4a78-972f-e31bfb1d8b11';
    // seqTipoRelacionamentoTecnicoAgendado = 'a929318e-136c-4573-9ce8-372f5188ca82';
    // seqTipoRelacionamentoTecnicoResponsavel = 'c752aa3c-98fd-466b-a7d0-9f71a9fa9a86';
    // seqTipoRelacionamentoSolicitante = 'becc8f4d-57cb-4b00-a57c-c0669d5de85b';
    // seqTipoRelacionamentoDependenteIexcombo = '59289ace-6ec9-431a-a186-74085b97ec9c';
    // seqTipoRelacionamentoBase = 'ecf93dd3-5b33-41df-803d-d4f21529a8f8';

    /* FORMA PAGAMENTO */
    // seqFormaPagamentoPix = '3748d622-af97-41f2-871b-abb6075b1a34';
    // seqFormaPagamentoCartaoCredito = 'e0c37ea2-0d6b-406f-abfe-e9487a21425e';

    /* CONDIÇÃO PAGAMENTO FP */
    // seqCondicaoPagamentoFpCartaoCredito = 'c203fa1d-ef4a-4997-ac68-c64909dc4de5';

    /* DOCUMENTO ITEM CARACTERÍSTICA */
    // seqDocumentoItemCaracteristicaInstalacao = 'f9a36252-03c6-4d3b-966f-0d23bcf2a7c4';
    // seqDocumentoItemCaracteristicaManutencao = '0ec0c01e-7c83-466c-bf13-5cae7d6dc113';

    /* BANCO */
    seqContaBancaria = '0717dad2-bff1-4eab-adf6-c47ab4b42d42';

    /* FINANCEIRO */
    seqContaFinanceiraReceber = '4b6f7249-e293-48df-8425-09d44d550b0e';
    // seqContaFinanceiraPagar = '0520776a-3a36-4869-81d8-09eca0f139cd';

    /* TIPO ENDEREÇO */
    seqTipoEnderecoEntrega = '7ffaf5b9-ad2e-4cf8-b30e-25a61fdcaf09';
    seqTipoEnderecoCobranca = 'b4062983-6166-4c91-8319-a25287b505b2';


    /* ITEM CARACTERISTICA */
    seqItemCaracteristica = '1f02b95a-758b-4480-9f22-8b8d7bd3f7d0';

    /* ITEM CATEGORIA CARACTERÍSTICA */
    // seqItemCategoriaCaracteristaItemTotem = '7b9dfec9-5ef0-4045-979f-cab45bff5376';

    /* TIPO CONTATO */
    // seqTipoContatoCelular = '215e44df-7fb0-46ba-81ea-fc47984aa7ac';
    // seqTipoContatoWhatsApp = '40c9d9fa-df1f-49f1-8f90-59dc2001f093';
    // seqTipoContatoEmail = '787b95b2-d72b-46b4-8f94-9ec19f58b117';

    /* TIPO DOCUMENTO */
    seqTipoDocumento = '787a7daf-46ab-4068-9c8f-e1dd47d0f8ba';

    /* TIPO DOCUMENTO PROCESSO */
    seqTipoDocumentoProcessoContratoAtivo = '5ee842dd-ec07-4636-b7c5-8f38543fc271';

    /* ID */
    idListaContaBancaria = 'CB';
    idListaContaFinanceira = 'CF';
    idListaFormaPagamento = 'FP';

    /* TAXAS PESSOA */
    taxaBoleto = 3.8;
    taxaCredito = 4.8;
    taxaPix = 1.8;
    tipoTaxaBoleto = "2";
    tipoTaxaCredito = "1";
    tipoTaxaPix = "2";
}