import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
import { Component } from "react";
import Constante from "../../../util/Constante";
import Util from "../../../util/Util";

export default class ProjetosHotsite extends Component {
    util = new Util();
    constante = new Constante();

    render() {
        return <main style={{maxWidth: 1200, width: '95%', margin: '0 auto' }}>

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '30px 0', flexWrap: 'wrap' }}>
                {this.props.listaItemCategoria.map((pItemCategoria, index) =>
                    <>
                        {index === 0 && <span>|</span>}
                        <Button
                            key={Math.random()}
                            onClick={() => window.location.href = "/pesquisa?ic=" + pItemCategoria._seqItemCategoria}
                            label={pItemCategoria.nome}
                            className="p-button-link"
                            style={{ color: 'var(--primary-800)', fontSize: 13 }} />
                        <span>|</span>
                    </>
                )}
            </div>

            <h2
                key={Math.random()}
                style={{ color: 'var(--primary-800)', paddingBottom: 15 }}>Projetos</h2>

            <div style={{borderTop: '1px solid var(--primary-800)'}}></div>

            <div
                key={Math.random()}
                className="h-projetos-container">
                {this.props.listaItem.map(pItem =>
                    <div
                        key={Math.random()}
                        className="h-projetos-card"
                        onClick={() => window.open("/checkout?id=" + pItem._seqItem)}>
                        <img
                            key={Math.random()}
                            src={pItem.imagem ? this.props.urlBase + pItem.imagem : this.constante.url_imagem_placeholder} style={{ width: '100%', height: 150, objectFit: 'cover', borderTopLeftRadius: 10, borderTopRightRadius: 10, }} alt="" />

                        <div
                            key={Math.random()}
                            style={{ height: 190, padding: 10, display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'space-between' }}>
                            <div key={Math.random()}>
                                <Tag key={Math.random()}>{pItem._itemCategoriaNome || "Sem categoria"}</Tag>

                                <div
                                    key={Math.random()}
                                    style={{ marginTop: 10 }}>
                                    <h3 key={Math.random()}>{pItem.nome}</h3>
                                    <h5 key={Math.random()}>{pItem.descricao}</h5>
                                </div>
                            </div>

                            <div
                                key={Math.random()}
                                style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: "space-between", }}>
                                <div
                                    key={Math.random()}
                                    style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                                    <i
                                        key={Math.random()}
                                        className="pi pi-user"></i>
                                </div>

                                <p
                                    key={Math.random()}
                                    style={{ fontSize: 15, color: 'gray', fontWeight: 'bold' }}>Desde {this.util.formatarDataComTimestamp(pItem.dataCadastro)}</p>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </main>
    }
}