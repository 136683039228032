import { Component } from "react";
import logoPalluaBranco from '../../../assets/images/logo-pallua-branco.png';
import { Button } from "primereact/button";
import { Navigate } from "react-router-dom";

export default class Footer extends Component {

    state = {
        liberarAcesso: {
            principal: false,
            quem_somos: false,
            depoimentos: false,
            pesquisa: false,
            login: false,
            cadastro: false,
            taxas_e_servicos: false,
            termos_de_uso: false,
            politica_de_privacidade: false,
        }
    }

    navegar(pTela) {
        const liberarAcesso = this.state.liberarAcesso;
        const path_atual = window.location.pathname.split("/")[1];

        window.scrollTo(0,0);

        if (pTela === path_atual) return;

        liberarAcesso[pTela] = true;

        this.setState({ liberarAcesso: liberarAcesso });
    }

    render() {
        return <>
            {this.state.liberarAcesso.principal && <Navigate to="/principal" />}
            {this.state.liberarAcesso.quem_somos && <Navigate to="/quem_somos" />}
            {this.state.liberarAcesso.depoimentos && <Navigate to="/depoimentos" />}
            {this.state.liberarAcesso.pesquisa && <Navigate to="/pesquisa" />}
            {this.state.liberarAcesso.login && <Navigate to="/login" />}
            {this.state.liberarAcesso.cadastro && <Navigate to="/cadastro" />}
            {this.state.liberarAcesso.pesquisa && <Navigate to="/pesquisa" />}
            {this.state.liberarAcesso.taxas_e_servicos && <Navigate to="/taxas_e_servicos" />}
            {this.state.liberarAcesso.termos_de_uso && <Navigate to="/termos_de_uso" />}
            {this.state.liberarAcesso.politica_de_privacidade && <Navigate to="/politica_de_privacidade" />}

            <footer style={{ padding: '50px', backgroundColor: 'var(--primary-800)', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 50 }}>
                <div className="h-footer-nav-container">
                    <div>
                        <h4>Pallua</h4>

                        <div className="h-footer-nav-link">
                            <Button label="Quem somos" className="p-button-link" style={{ color: 'white', padding: 0, textAlign: 'left', fontSize: 15 }} onClick={() => this.navegar("quem_somos")} />
                            <Button label="Depoimentos" className="p-button-link" style={{ color: 'white', padding: 0, textAlign: 'left', fontSize: 15 }} onClick={() => this.navegar("depoimentos")} />
                            <Button label="Login" className="p-button-link" style={{ color: 'white', padding: 0, textAlign: 'left', fontSize: 15 }} onClick={() => this.navegar("login")} />
                        </div>
                    </div>

                    <div>
                        <h4>Ajuda</h4>

                        <div className="h-footer-nav-link">
                            <Button label="Taxas e serviços" className="p-button-link" style={{ color: 'white', padding: 0, textAlign: 'left', fontSize: 15 }} onClick={() => this.navegar("taxas_e_servicos")} />
                            <Button label="Termos de uso" className="p-button-link" style={{ color: 'white', padding: 0, textAlign: 'left', fontSize: 15 }} onClick={() => this.navegar("termos_de_uso")} />
                            <Button label="Política de privacidade" className="p-button-link" style={{ color: 'white', padding: 0, textAlign: 'left', fontSize: 15 }} onClick={() => this.navegar("politica_de_privacidade")} />
                        </div>
                    </div>

                    <div>
                        <h4>Faça sua campanha</h4>

                        <div className="h-footer-nav-link">
                            <Button label="Comece seu projeto" className="p-button-link" style={{ color: 'white', padding: 0, textAlign: 'left', fontSize: 15 }} onClick={() => this.navegar("cadastro")} />
                            <Button label="Explore projetos" className="p-button-link" style={{ color: 'white', padding: 0, textAlign: 'left', fontSize: 15 }} onClick={() => this.navegar("pesquisa")} />
                        </div>
                    </div>

                    <div>
                        <h4>Redes sociais</h4>

                        <div className="h-footer-nav-link">
                            <Button label="Facebook" className="p-button-link" style={{ color: 'white', padding: 0, textAlign: 'left', fontSize: 15 }} onClick={() => window.open("https://www.facebook.com/palluaoficial")} />
                            <Button label="Instagram" className="p-button-link" style={{ color: 'white', padding: 0, textAlign: 'left', fontSize: 15 }} onClick={() => window.open("https://www.instagram.com/palluaoficial/")} />
                        </div>
                    </div>

                    <div>
                        <h4>Fale conosco</h4>

                        <div className="h-footer-nav-link">
                            <Button label="(21) 3540-3145" className="p-button-link" style={{ color: 'white', padding: 0, textAlign: 'left', fontSize: 15 }} />
                            <Button label="relacionamento@pallua.com.br" className="p-button-link" style={{ color: 'white', padding: 0, textAlign: 'left', fontSize: 15 }} />
                            <Button label="Segunda a sexta-feira, das 8h às 17h" className="p-button-link" style={{ color: 'white', padding: 0, textAlign: 'left', fontSize: 15 }} />
                        </div>
                    </div>
                </div>

                <img src={logoPalluaBranco} style={{ width: 180, objectFit: 'contain' }} alt="" />

                <small style={{ color: 'white' }}>Copyright © Crossystem Tecnologia da Informação - 2020</small>
            </footer>
        </>
    }
}