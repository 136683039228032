import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Component } from "react";
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import Item from "lirida-back-service/Servicos/Item/Item";
import ItemRelacionamento from "lirida-back-service/Servicos/ItemRelacionamento/ItemRelacionamento";
import ItemTag from "lirida-back-service/Servicos/ItemTag/ItemTag";
import ItemService from "lirida-back-service/Servicos/Item/ItemService";
import ItemCategoria from "lirida-back-service/Servicos/ItemCategoria/ItemCategoria";
import ItemCategoriaService from "lirida-back-service/Servicos/ItemCategoria/ItemCategoriaService";
import Constante from "../../util/Constante";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import Util from "../../util/Util";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { TabView, TabPanel } from 'primereact/tabview';
import { Checkbox } from 'primereact/checkbox';
import { InputNumber } from "primereact/inputnumber";
import { Link } from "react-router-dom";

export default class ProjetoDetalhe extends Component {
    constante = new Constante();
    util = new Util();
    itemService = new ItemService(this.constante.token, this.constante.url_base);
    itemCategoriaService = new ItemCategoriaService(this.constante.token, this.constante.url_base);

    listaOrdem = [
        { label: "1", value: 1 },
        { label: "2", value: 2 },
        { label: "3", value: 3 },
        { label: "4", value: 4 },
        { label: "5", value: 5 },
        { label: "6", value: 6 },
        { label: "7", value: 7 },
        { label: "8", value: 8 },
        { label: "9", value: 9 },
    ]

    state = {
        pessoaUsuario: new Pessoa(),
        item: new Item(),
        itemTag: new ItemTag(),
        itemCategoria: new ItemCategoria(),
        listaItemCategoria: [],
        listaItemTag: [],
        liberarAcesso: {
            tela_exemplo1: false,
        },
        salvarCarregando: false,
        deletarCarregando: false,
        salvarTagCarregando: false,
        modalDeletarVisivel: false,
        modalDeletarTagVisivel: false,
        modalItemTagVisivel: false,
        urlBase: '',
        base64: '',
        itemTagTitulo: '',
        inputError: {
            codigo: false,
            nome: false,
            descricao: false,
            categoria: false,
            situacao: false,
        },
        inputTagError: {
            tagTitulo: false,
            tagValor: false
        }
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        const pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
        const urlBase = await this.util.buscarUrlBaseArquivo();
        const item = this.props.item;
        let itemCategoria = new ItemCategoria();

        await this.listarTag(item);

        const listaItemCategoria = await this.listarItemCategoria();

        for (let i = 0; i < listaItemCategoria.length; i++) {
            if (item.seqItemCategoria === listaItemCategoria[i]._seqItemCategoria) {
                itemCategoria = listaItemCategoria[i];
            }
        }
        console.log(listaItemCategoria)
        console.log(itemCategoria)

        this.setState({
            pessoaUsuario: pessoaUsuario,
            item: item,
            itemCategoria: itemCategoria,
            urlBase: urlBase,
        });
    }

    async salvar() {
        let item = this.state.item;
        const itemCategoria = this.state.itemCategoria;
        const inputError = this.state.inputError;
        const listaChaveInputError = Object.keys(inputError);

        for (let i = 0; i < listaChaveInputError.length; i++) {
            inputError[listaChaveInputError[i]] = false;
        }
        if (!this.state.item.nome) {
            inputError.nome = true;
        }

        if (!this.state.item.descricao) {
            inputError.descricao = true;
        }

        if (!this.state.itemCategoria._seqItemCategoria) {
            inputError.categoria = true;
        }

        if (!this.state.item.situacao) {
            inputError.situacao = true;
        }

        let inputOK = true;

        for (let i = 0; i < listaChaveInputError.length; i++) {
            if (inputError[listaChaveInputError[i]]) {
                inputOK = false;
            }
        }

        this.setState({ inputError: inputError });

        if (!inputOK) {
            return;
        }

        this.setState({ salvarCarregando: true });

        let itemNovo = true;

        item.seqUsuario = this.constante.seqUsuario;
        item.seqItemCategoria = itemCategoria._seqItemCategoria;

        if (item._seqItem) {
            itemNovo = false;
        }

        if (this.state.base64) {
            item.imagem = this.state.base64;
        }

        const retornoItem = await this.itemService.salvar(item);

        item = retornoItem.objeto;

        if (itemNovo) {
            const itemRelacionamento = new ItemRelacionamento();

            itemRelacionamento.seqUsuario = this.constante.seqUsuario;
            itemRelacionamento.seqItem = item._seqItem;
            itemRelacionamento.seqTipoRelacionamento = this.constante.seqTipoRelacionamentoItemDonoProjeto;
            itemRelacionamento.seqPessoaRelacionada = this.state.pessoaUsuario._seqPessoa;

            await this.itemService.salvarRelacionamento(itemRelacionamento);
        }

        this.props.listar();

        this.props.toast.current.show([
            { severity: 'success', summary: 'Projeto armazenado com sucesso!' },
        ]);

        this.setState({
            salvarCarregando: false,
            item: item
        })
    }

    async deletar() {
        this.setState({ deletarCarregando: true });
        const item = this.state.item;

        const retorno = await this.itemService.listarRelacionamento(item);
        const listaItemRelacionamento = retorno.objeto;

        for (let i = 0; i < listaItemRelacionamento.length; i++) {
            await this.itemService.deletarRelacionamento(listaItemRelacionamento[i]);
        }

        await this.itemService.deletar(item._seqItem);

        this.voltar();

        this.props.toast.current.show([
            { severity: 'success', summary: 'Projeto deletado com sucesso!' },
        ]);
    }

    voltar() {
        this.props.listar();
        this.props.setState({
            item: new Item(),
            itemTag: new ItemTag(),
            itemCategoria: new ItemCategoria(),
            tela: "1",
            imagemPreview: '',
        });
    }

    async listarItemCategoria() {
        const retorno = await this.itemCategoriaService.listar(this.constante.seqUsuario);
        const listaItemCategoria = retorno.objeto;

        console.log(listaItemCategoria)

        this.setState({
            listaItemCategoria: listaItemCategoria,
        });

        return listaItemCategoria;
    }

    async salvarTag() {
        let itemTag = this.state.itemTag;
        const inputTagError = this.state.inputTagError;
        const listaChaveInputTagError = Object.keys(inputTagError);

        for (let i = 0; i < listaChaveInputTagError.length; i++) {
            inputTagError[listaChaveInputTagError[i]] = false;
        }

        if (!itemTag.tag) {
            inputTagError.tagTitulo = true;
        }

        let inputOK = true;

        for (let i = 0; i < listaChaveInputTagError.length; i++) {
            if (inputTagError[listaChaveInputTagError[i]]) {
                inputOK = false;
            }
        }

        this.setState({ inputTagError: inputTagError });

        if (!inputOK) {
            return;
        }

        this.setState({ salvarTagCarregando: true, })

        if (!itemTag._seqItemTag) {
            itemTag.seqItem = this.state.item._seqItem;
        }

        if(!itemTag.valor) {
            itemTag.valor = 0;
            itemTag.opc = "true";
        }

        console.log(itemTag);

        await this.itemService.salvarTag(itemTag);
        await this.listarTag();

        this.props.toast.current.show([
            { severity: 'success', summary: 'Tag armazenada com sucesso!' },
        ]);

        this.setState({
            itemTag: new ItemTag(),
            salvarTagCarregando: false,
            modalItemTagVisivel: false
        });
    }

    async listarTag(pItem) {
        let item;

        if (pItem) {
            item = pItem;
        } else {
            item = this.state.item;
        }

        const retorno = await this.itemService.listarTag(item);
        const listaItemTag = retorno.objeto;

        listaItemTag.sort(function (a, b) {
            if (a?.tags0 > b?.tags0) return 1;
            if (a?.tags0 < b?.tags0) return -1;
            return 0;
        })

        this.setState({ listaItemTag: listaItemTag });
    }

    async deletarTag(pItemTag) {
        if (this.state.listaItemTag.length === 1) {
            this.props.toast.current.show([
                { severity: 'error', summary: 'Você deve ter no mínimo 1 opção de pagamento.' },
            ])
            return;
        }
        await this.itemService.deletarTag(pItemTag);
        this.props.toast.current.show([
            { severity: 'success', summary: 'opção de pagamento deletada com sucesso!' },
        ])
        this.listarTag();
    }

    async selecionarItemImagem(pArquivo) {
        const retorno = await this.util.pegarBase64(pArquivo);
        console.log(retorno)
        this.setState({ base64: retorno });
    }

    render() {
        return <div>
            <Dialog header="Alerta" visible={this.state.modalDeletarVisivel} style={{ width: 400 }} onHide={() => this.setState({ modalDeletarVisivel: false, })}>
                <div style={{ display: "flex", flexDirection: 'column', gap: 20 }}>
                    <p>Deseja realmente deletar?</p>
                    <div style={{ display: 'flex', gap: 10, justifyContent: "right" }}>
                        <Button label="Sim" onClick={() => {
                            this.deletar();
                            this.setState({ modalDeletarVisivel: false, });
                        }} />
                        <Button label="Não" onClick={() => this.setState({ modalDeletarVisivel: false, })} />
                    </div>
                </div>
            </Dialog>

            <Dialog header="Alerta" visible={this.state.modalDeletarTagVisivel} style={{ width: 400 }} onHide={() => this.setState({ modalDeletarTagVisivel: false, })}>
                <div style={{ display: "flex", flexDirection: 'column', gap: 20 }}>
                    <p>Deseja realmente deletar esta opção de pagamento?</p>
                    <div style={{ display: 'flex', gap: 10, justifyContent: "right" }}>
                        <Button label="Sim" onClick={() => {
                            this.deletarTag(this.state.itemTag);
                            this.setState({ modalDeletarTagVisivel: false, });
                        }} />
                        <Button label="Não" onClick={() => this.setState({ modalDeletarTagVisivel: false, })} />
                    </div>
                </div>
            </Dialog>

            <Dialog header={this.state.itemTag._seqItemTag ? "Alterar: " + this.state.itemTagTitulo : "Nova opção de pagamento"} visible={this.state.modalItemTagVisivel} style={{ width: 1000 }} onHide={() => this.setState({ modalItemTagVisivel: false, })}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 15, border: '1px solid lightgray', padding: 10, borderRadius: 3 }}>
                    <form
                        onSubmit={e => {
                            e.preventDefault();
                            this.salvarTag();
                        }} >
                        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", gap: 20 }}>
                            <div>
                                <label>Título</label><br />
                                <InputText
                                    value={this.state.itemTag.tag || ''}
                                    onChange={e => this.setState({
                                        itemTag: {
                                            ...this.state.itemTag,
                                            tag: e.target.value,
                                        }
                                    })}
                                    style={{ width: "100%", borderColor: this.state.inputTagError.tagTitulo && 'var(--red-600)' }} />
                                {this.state.inputTagError.tagTitulo && <small style={{ color: 'var(--red-600)' }}>Título inválido</small>}
                            </div>
                            <div>
                                <label>Valor</label><br />
                                <InputNumber
                                    mode="currency"
                                    currency="BRL"
                                    locale="pt-BR"
                                    type="tel"
                                    value={this.state.itemTag.valor}
                                    onChange={e => this.setState({
                                        itemTag: {
                                            ...this.state.itemTag,
                                            valor: e.value
                                        }
                                    })}
                                    style={{ width: "100%" }}
                                    inputStyle={{ borderColor: this.state.inputTagError.tagValor && 'var(--red-600)' }} />
                                {this.state.inputTagError.tagValor && <small style={{ color: 'var(--red-600)' }}>Valor inválido</small>}
                            </div>

                            <div>
                                <label>Ordem</label><br />
                                <Dropdown
                                    placeholder="Selecione"
                                    options={this.listaOrdem}
                                    onChange={e => this.setState({
                                        itemTag: {
                                            ...this.state.itemTag,
                                            tags0: e.value
                                        }
                                    })}
                                    value={this.state.itemTag.tags0}
                                    style={{ width: "100%" }}
                                    inputStyle={{ borderColor: this.state.inputTagError.tagOrdem && 'var(--red-600)' }} />
                                {this.state.inputTagError.tagOrdem && <small style={{ color: 'var(--red-600)' }}>Ordem inválida</small>}
                            </div>

                            <div>
                                <label>Permitir alterar valor?</label><br />
                                <Checkbox
                                    checked={Boolean(this.state.itemTag.opc)}
                                    onChange={e => this.setState({
                                        itemTag: {
                                            ...this.state.itemTag,
                                            opc: e.checked || null,
                                        }
                                    })} />
                            </div>
                        </div>

                        <div style={{ display: "flex", justifyContent: 'right' }}>
                            <Button loading={this.state.salvarTagCarregando} label="Salvar" className="p-button-raised" type="submit" />
                            {/* {this.state.itemTag._seqItemTag && <Button label="Cancelar" className="p-button-outlined" type="button" onClick={() => this.setState({ itemTag: new ItemTag() })} />} */}
                        </div>
                    </form>
                </div>
            </Dialog>

            <main style={{ width: '95%', margin: '20px auto' }}>
                <div style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 20, margin: '20px 0' }}>
                            <Button label={<i className="pi pi-arrow-left"></i>} className="p-button-raised" onClick={() => this.voltar()} />

                            <div>
                                <h1 style={{ fontSize: 22 }}>Detalhe do projeto</h1>
                                <h2 style={{ fontSize: 20, color: "gray" }}>
                                    {this.state.item._seqItem ? this.state.item.nome : "Novo projeto"}
                                </h2>
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 10, }}>
                            <Button loading={this.state.salvarCarregando} label="Salvar" className="p-button-raised" onClick={() => {
                                this.salvar();
                                this.setState({ modalItemTagVisivel: false });
                            }} />
                        </div>
                    </div>

                    {this.state.item._seqItem &&
                        <div>
                            <hr></hr><br />
                            <h3 style={{ fontSize: 18 }}>Link do checkout</h3>
                            {this.state.pessoaUsuario.contaS2pCodigo ?
                                <Link onClick={e => { e.preventDefault(); window.open(this.constante.linkProjeto + this.state.item._seqItem, "_blank") }} to="">
                                    {this.constante.linkProjeto + this.state.item._seqItem}
                                </Link>
                                :
                                <p style={{ color: 'var(--indigo-600)' }}>É importante você manter seus dados atualizados para você receber o seu dinheiro. Cadastre seus Dados bancários no seu Perfil!</p>
                            }
                        </div>
                    }
                </div>

                <div style={{ display: 'flex', alignItems: 'center', border: '1px solid lightgray', padding: 10, borderRadius: 3, marginTop: 20, gap: 10 }}>
                    <img src={this.state.urlBase + this.state.item.imagem} alt={this.state.item.nome} style={{ width: 200, maxHeight: 200, objectFit: 'contain', backgroundColor: 'lightgray' }} />
                    <div>
                        <label>Foto do projeto</label><br />
                        <InputText type="file" onChange={e => this.selecionarItemImagem(e.target.files[0])} />
                    </div>
                </div>

                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", gap: 20, border: '1px solid lightgray', padding: 10, borderRadius: 3, marginTop: 20 }}>
                    <div>
                        <label>Nome</label><br />
                        <InputText
                            value={this.state.item.nome || ''}
                            onChange={e => this.setState({
                                item: {
                                    ...this.state.item,
                                    nome: e.target.value,
                                }
                            })}
                            style={{ width: '100%', borderColor: this.state.inputError.nome && 'var(--red-600)' }} />
                        {this.state.inputError.nome && <small style={{ color: 'var(--red-600)' }}>Nome inválido</small>}
                    </div>
                    <div>
                        <label>Descrição</label><br />
                        <InputText
                            value={this.state.item.descricao || ''}
                            onChange={e => this.setState({
                                item: {
                                    ...this.state.item,
                                    descricao: e.target.value,
                                }
                            })}
                            style={{ width: '100%', borderColor: this.state.inputError.descricao && 'var(--red-600)' }} />
                        {this.state.inputError.descricao && <small style={{ color: 'var(--red-600)' }}>Descrição inválida</small>}
                    </div>
                    <div>
                        <label>Região</label><br />
                        <Dropdown
                            placeholder={"Selecione"}
                            options={this.state.listaItemCategoria}
                            optionLabel="nome"
                            value={this.state.itemCategoria}
                            onChange={e => this.setState({ itemCategoria: e.value })}
                            style={{ width: '100%', borderColor: this.state.inputError.categoria && 'var(--red-600)' }} />
                        {this.state.inputError.categoria && <small style={{ color: 'var(--red-600)' }}>Região inválido</small>}
                    </div>
                    <div>
                        <label>Situação</label><br />
                        <Dropdown
                            placeholder="Selecione"
                            options={[
                                { label: "ATIVO", value: "ATIVO" },
                                { label: "INATIVO", value: "INATIVO" }
                            ]}
                            value={this.state.item.situacao}
                            onChange={e => this.setState({
                                item: {
                                    ...this.state.item,
                                    situacao: e.target.value
                                }
                            })}
                            style={{ width: '100%', borderColor: this.state.inputError.situacao && 'var(--red-600)' }} />
                        {this.state.inputError.situacao && <small style={{ color: 'var(--red-600)' }}>Situação inválida</small>}
                    </div>
                </div>

                {this.state.item._seqItem &&
                    <TabView style={{ marginTop: 20 }}>
                        <TabPanel header="Opções de pagamento">

                            <div style={{ display: 'flex', justifyContent: 'right' }}>
                                <Button
                                    label="Novo"
                                    onClick={() => this.setState({ modalItemTagVisivel: true, itemTag: new Item(), itemTagTitulo: "", })} />
                            </div>

                            <DataTable
                                style={{ marginTop: 20 }}
                                showGridlines
                                removableSort
                                value={this.state.listaItemTag}>
                                {/* <Column field="_seqItemTag" header="Id" sortable></Column> */}
                                <Column field="tag" header="Título" sortable></Column>
                                <Column field="valor" header="Valor" sortable body={pItem =>
                                    <p>{this.util.formatarValor(pItem.valor)}</p>
                                }></Column>
                                <Column field="opc" header="Alterável" sortable body={pItem =>
                                    pItem.opc ? "Sim" : "Não"
                                }></Column>
                                <Column header="Ordem" body={pItem =>
                                    <p>{pItem?.tags0}</p>
                                } sortable></Column>
                                <Column field="icone" header="Ação" sortable body={pItem =>
                                    <div style={{ display: 'flex', gap: 10 }}>
                                        <Button label={<i className="pi pi-eye"></i>} onClick={() => this.setState({ modalItemTagVisivel: true, itemTag: pItem, itemTagTitulo: pItem.tag })} />
                                        <Button className="p-button-danger" label={<i className="pi pi-trash"></i>} onClick={() => this.setState({ modalDeletarTagVisivel: true, itemTag: pItem })} />
                                    </div>
                                }></Column>
                            </DataTable>
                        </TabPanel>
                    </TabView>
                }
            </main >
        </div >
    }
}