import { Component, createRef } from "react";
import Header from "./components/Header";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import Item from 'lirida-back-service/Servicos/Item/Item';
import ItemService from "lirida-back-service/Servicos/Item/ItemService";
import Constante from "../../util/Constante";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import Util from "../../util/Util";
import DocumentoItem from "lirida-back-service/Servicos/Documento/DocumentoItem";
import DocumentoService from "lirida-back-service/Servicos/Documento/DocumentoService";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import CompromissoDetalhe from "./CompromissoDetalhe";
import { Toast } from 'primereact/toast';
import { InputText } from "primereact/inputtext";

export default class Compromisso extends Component {
    toast = createRef();
    constante = new Constante();
    itemService = new ItemService(this.constante.token, this.constante.url_base);
    documentoService = new DocumentoService(this.constante.token, this.constante.url_base);
    util = new Util();

    state = {
        pessoaUsuario: new Pessoa(),
        conteudo: '',
        listaDocumentoItem: [],
        documentoItem: new DocumentoItem(),
        listaItem: [],
        valorLiquido: 0,
        valorBruto: 0,
        item: new Item(),
        situacao: '',
        dataCadastro_inicial: undefined,
        dataCadastro_final: undefined,
        dataProximoVencimento_inicial: undefined,
        dataProximoVencimento_final: undefined,
        tela: "1",
        urlBase: '',
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        const pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
        const urlBase = await this.util.buscarUrlBaseArquivo();

        await this.listarProjeto(pessoaUsuario);
        await this.listar(pessoaUsuario);

        this.setState({
            pessoaUsuario: pessoaUsuario,
            urlBase: urlBase
        });
    }

    async listarProjeto(pPessoa) {
        const listaPesquisa = [];
        let listaItem = this.state.listaItem;
        let pessoaUsuario;

        if (pPessoa) {
            pessoaUsuario = pPessoa;
        } else {
            pessoaUsuario = this.state.pessoaUsuario;
        }

        this.setState({
            listaItem: listaItem,
        })

        let pesquisa = new Pesquisa();
        pesquisa.campo = "item.seq_usuario";
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = "";
        pesquisa.tipo = "TEXTO";

        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = "item_relacionamento.seq_tipo_relacionamento";
        pesquisa.conteudo = this.constante.seqTipoRelacionamentoItemDonoProjeto;
        pesquisa.operacao = "AND";
        pesquisa.tipo = "TEXTO";

        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = "item_relacionamento.seq_pessoa_relacionada";
        pesquisa.conteudo = pessoaUsuario._seqPessoa;
        pesquisa.operacao = "AND";
        pesquisa.tipo = "TEXTO";

        listaPesquisa.push(pesquisa);

        const retorno = await this.itemService.listarComFiltro(listaPesquisa);
        listaItem = retorno.objeto;

        this.setState({ listaItem: listaItem });
    }

    async listar(pPessoa) {
        let pessoaUsuario;
        const listaPesquisa = [];

        if (pPessoa) {
            pessoaUsuario = pPessoa;
        } else {
            pessoaUsuario = this.state.pessoaUsuario;
        }

        listaPesquisa.push(
            this.util.retornaPesquisa(
                "documento_item.seq_usuario",
                this.constante.seqUsuario,
                "",
                "TEXTO"
            ));

        listaPesquisa.push(
            this.util.retornaPesquisa(
                "documento_item_relacionamento.seq_pessoa_relacionada",
                pessoaUsuario._seqPessoa,
                "AND",
                "TEXTO"
            ));

        if (this.state.item._seqItem) {
            listaPesquisa.push(
                this.util.retornaPesquisa(
                    "documento_item.seq_item",
                    this.state.item._seqItem,
                    "AND",
                    "TEXTO"
                ));
        }

        if (this.state.conteudo) {
            listaPesquisa.push(
                this.util.retornaPesquisa(
                    "pessoa.nome",
                    this.state.conteudo,
                    "AND",
                    "TEXTO"
                ));
        }

        if (this.state.dataCadastro_inicial) {
            listaPesquisa.push(
                this.util.retornaPesquisa(
                    "documento_item.data_cadastro",
                    this.state.dataCadastro_inicial,
                    "AND",
                    "DATA_INICIAL"
                ));
        }

        if (this.state.dataCadastro_final) {
            listaPesquisa.push(
                this.util.retornaPesquisa(
                    "documento_item.data_cadastro",
                    this.state.dataCadastro_final,
                    "AND",
                    "DATA_FINAL"
                ));
        }

        if (this.state.dataProximoVencimento_inicial) {
            listaPesquisa.push(
                this.util.retornaPesquisa(
                    "documento_item.data_proxima_recorrencia",
                    this.state.dataProximoVencimento_inicial,
                    "AND",
                    "DATA_INICIAL"
                ));
        }

        if (this.state.dataProximoVencimento_final) {
            listaPesquisa.push(
                this.util.retornaPesquisa(
                    "documento_item.data_proxima_recorrencia",
                    this.state.dataProximoVencimento_final,
                    "AND",
                    "DATA_FINAL"
                ));
        }

        if (this.state.situacao) {
            listaPesquisa.push(
                this.util.retornaPesquisa(
                    "AND documento_item.situacao = '" + this.state.situacao + "'",
                    this.state.situacao,
                    "AND",
                    "MANUAL"
                ));
        }


        console.log(listaPesquisa)

        const retornoDocumento = await this.documentoService.listarItemComFiltro(listaPesquisa);
        const listaDocumentoItem = retornoDocumento.objeto;

        console.log("lista ---> ", listaDocumentoItem[0]);

        let valorLiquido = 0;

        listaDocumentoItem.forEach(pDocumentoItem => {
            if (pDocumentoItem.precoUnitario) {
                valorLiquido = pDocumentoItem.precoUnitario + valorLiquido
            }
        });

        this.setState({
            listaDocumentoItem: listaDocumentoItem,
            valorLiquido: valorLiquido
        })
    }

    selecionar(pDocumentoItem) {
        this.setState({
            tela: "2",
            documentoItem: pDocumentoItem,
        })
    }

    render() {
        return <div>
            <Header />

            <Toast ref={this.toast} />

            {this.state.tela === "2" ?
                <CompromissoDetalhe
                    toast={this.toast}
                    documentoItem={JSON.stringify(this.state.documentoItem)}
                    setState={e => this.setState(e)}
                    listar={() => this.listar()}
                />
                :
                <main style={{ width: '95%', margin: '20px auto' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: '1px solid lightgray', padding: 10, borderRadius: 3 }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 20, margin: '20px 0' }}>
                            <h1 style={{ fontSize: 22 }}>Contratos</h1>
                        </div>
                    </div>

                    <form
                        onSubmit={e => { e.preventDefault(); this.listar() }}
                        style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3, marginTop: 20, display: 'flex', flexDirection: 'column', gap: 15 }}>

                        <h1 style={{ fontSize: 20 }}>Filtros de busca</h1>

                        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 20 }}>
                            <div>
                                <label>Nome da pessoa</label><br />
                                <InputText
                                    style={{ width: "100%" }}
                                    onChange={e => this.setState({
                                        conteudo: e.target.value
                                    })}
                                    value={this.state.conteudo} />
                            </div>
                            <div>
                                <label>Projeto</label><br />
                                <Dropdown
                                    placeholder="Todos"
                                    options={[
                                        { ...new Item(), nome: 'Todos', _seqItem: 0 },
                                        ...this.state.listaItem
                                    ]}
                                    optionLabel="nome"
                                    value={this.state.item}
                                    onChange={e => this.setState({ item: e.value })}
                                    style={{ width: '100%', }} />
                            </div>
                            <div>
                                <label>Data do próximo de vencimento inicial</label><br />
                                <Calendar
                                    showButtonBar
                                    style={{ width: '100%', }}
                                    onChange={e => this.setState({ dataProximoVencimento_inicial: e.value.toLocaleDateString("pt-br") })}
                                    value={this.state.dataProximoVencimento_inicial}
                                    dateFormat="dd/mm/yy"
                                    placeholder="dd/mm/yyyy" />
                            </div>
                            <div>
                                <label>Data do próximo de vencimento final</label><br />
                                <Calendar
                                    showButtonBar
                                    style={{ width: '100%', }}
                                    onChange={e => this.setState({ dataProximoVencimento_final: e.value.toLocaleDateString("pt-br") })}
                                    value={this.state.dataProximoVencimento_final}
                                    dateFormat="dd/mm/yy"
                                    placeholder="dd/mm/yyyy" />
                            </div>
                            <div>
                                <label>Data cadastro inicial</label><br />
                                <Calendar
                                    showButtonBar
                                    style={{ width: '100%', }}
                                    onChange={e => this.setState({ dataCadastro_inicial: e.value.toLocaleDateString("pt-br") })}
                                    value={this.state.dataCadastro_inicial}
                                    dateFormat="dd/mm/yy"
                                    placeholder="dd/mm/yyyy" />
                            </div>
                            <div>
                                <label>Data cadastro final</label><br />
                                <Calendar
                                    showButtonBar
                                    style={{ width: '100%', }}
                                    onChange={e => this.setState({ dataCadastro_final: e.value.toLocaleDateString("pt-br") })}
                                    value={this.state.dataCadastro_final}
                                    dateFormat="dd/mm/yy"
                                    placeholder="dd/mm/yyyy" />
                            </div>
                            <div>
                                <label>Situação</label><br />
                                <Dropdown
                                    placeholder="Todos"
                                    options={[
                                        { label: 'Todos', value: '' },
                                        { label: 'ATIVO', value: 'ATIVO' },
                                        { label: 'INATIVO', value: 'INATIVO' },
                                    ]}
                                    value={this.state.situacao}
                                    onChange={e => this.setState({ situacao: e.value })}
                                    style={{ width: '100%', }} />
                            </div>
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 20 }}>
                            <div style={{ marginTop: 20, display: 'flex', alignItems: 'center', gap: 50 }}>
                                <div>
                                    <label>Valor líquido</label><br />
                                    <h2>{this.util.formatarValor(this.state.valorLiquido)}</h2>
                                </div>

                                <div>
                                    <label>Quantidade</label><br />
                                    <h2>{this.state.listaDocumentoItem.length}</h2>
                                </div>
                            </div>
                            <div>
                                <Button
                                    type="button" label="Limpar filtros" className="p-button-outlined"
                                    onClick={() => this.setState({
                                        item: new Item(),
                                        status: -1,
                                        dataCadastro_inicial: undefined,
                                        dataCadastro_final: undefined,
                                        dataProximoVencimento_inicial: undefined,
                                        dataProximoVencimento_final: undefined,
                                        conteudo: "",
                                        situacao: "",
                                    })} />
                                <Button type="submit" label="Buscar" className="p-button-raised" loading={this.state.carregando} style={{marginLeft: 10}}/>
                            </div>
                        </div>
                    </form>

                    <DataTable
                        style={{ marginTop: 20 }}
                        selectionMode="single"
                        showGridlines
                        removableSort
                        value={this.state.listaDocumentoItem}
                        responsiveLayout="scroll"
                        onSelectionChange={e => this.selecionar(e.value)}
                        paginator
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10, 20, 50]}>
                        {/* <Column field="_seqDocumentoItem" header="Id" sortable body={pItem =>
                            <p style={{ color: pItem.situacao === "ATIVO" ? "green" : "red" }}>{pItem._seqDocumentoItem}</p>
                        }></Column> */}
                        <Column field="_pessoaNome" header="Pessoa" sortable body={pItem =>
                            <p style={{ color: pItem.situacao === "ATIVO" ? "green" : "red" }}>{pItem._pessoaNome}</p>
                        }></Column>
                        <Column field="_itemNome" header="Projeto" sortable body={pItem =>
                            <p style={{ color: pItem.situacao === "ATIVO" ? "green" : "red" }}>{pItem._itemNome}</p>
                        }></Column>
                        <Column align="right" field="precoUnitario" header="Valor" sortable body={pItem =>
                            <p style={{ color: pItem.situacao === "ATIVO" ? "green" : "red" }}>{this.util.formatarValor(pItem.precoUnitario)}</p>
                        }></Column>
                        <Column field="dataCadastro" header="Data cadastro" sortable body={pItem =>
                            <p style={{ color: pItem.situacao === "ATIVO" ? "green" : "red" }}>{this.util.formatarDataComTimestamp(pItem.dataCadastro)}</p>
                        }></Column>
                        <Column field="dataProximaRecorrencia" header="Data do próximo vencimento" sortable body={pItem =>
                            pItem.dataProximaRecorrencia && <p style={{ color: pItem.situacao === "ATIVO" ? "green" : "red" }}>{this.util.formatarDataComTimestamp(pItem.dataProximaRecorrencia)}</p>
                        }></Column>
                        <Column field="situacao" header="Situação" sortable body={pItem =>
                            <p style={{ color: pItem.situacao === "ATIVO" ? "green" : "red" }}>{pItem.situacao}</p>
                        }></Column>
                    </DataTable>
                </main>
            }
        </div>
    }
}