import { Component } from "react";
import Footer from "./components/Footer";
import Header from "./components/Header";

export default class QuemSomos extends Component {
    render() {
        return <div>
            <Header />

            <main style={{ backgroundColor: "#FFBB05", padding: 50, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 50 }}>
                <h1 style={{ color: 'white' }}>Quem somos</h1>
                <div style={{ maxWidth: 1000, width: '100%', backgroundColor: 'white', borderRadius: 15, border: '1px solid gray', color: 'gray', padding: 20, fontSize: 17, display: "flex", flexDirection: 'column', gap: 10 }}>
                    <p>Pallua é uma plataforma digital de captação de recursos em operação deste setembro de 2018, criada e mantida pela empresa Crosssystem. Sabemos que a captação de recursos é essencial para realização de um projeto, por isso o Pallua é ideal para causas nobres de Pessoas Físicas, Pessoas Jurídicas e Organizações.</p>
                </div>
            </main>

            <Footer />
        </div>
    }
}