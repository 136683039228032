import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Component } from "react";
import { Dialog } from 'primereact/dialog';
import DocumentoItem from "lirida-back-service/Servicos/Documento/DocumentoItem";
import Util from "../../util/Util";
import DocumentoService from "lirida-back-service/Servicos/Documento/DocumentoService";
import Constante from "../../util/Constante";
import { Calendar } from "primereact/calendar";


export default class CompromissoDetalhe extends Component {
    util = new Util();
    constante = new Constante();
    documentoService = new DocumentoService(this.constante.token, this.constante.url_base);

    state = {
        documentoItem: new DocumentoItem(),
        vModalInativar: false,
        carregando: false,
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        let documentoItem = await JSON.parse(this.props.documentoItem);
        
        if (documentoItem.dataProximaRecorrencia) {
            let dataProximaRecorrencia = documentoItem.dataProximaRecorrencia.split("T")[0];

            let data = new Date();

            data.setDate(dataProximaRecorrencia.split("-")[0]);
            data.setMonth(Number(dataProximaRecorrencia.split("-")[1]) - 1);
            data.setFullYear(dataProximaRecorrencia.split("-")[2]);

            documentoItem.dataProximaRecorrencia = data;
        }

        this.setState({
            documentoItem: documentoItem,
        })
    }

    voltar() {
        this.props.listar();

        this.props.setState({
            tela: "1",
            documentoItem: new DocumentoItem(),
        })
    }

    async alterarSituacao(pSituacao) {
        let documentoItem = this.state.documentoItem;

        documentoItem.situacao = pSituacao;

        this.salvar(documentoItem);

        this.props.toast.current.show([
            { severity: 'success', summary: 'Compromisso atualizado com sucesso!' },
        ]);

        this.voltar();
    }

    async salvar(pDocumentoItem) {
        this.setState({ carregando: true });

        let documentoItem = pDocumentoItem;

        if (documentoItem.dataProximaRecorrencia) {
            documentoItem.dataProximaRecorrencia = documentoItem.dataProximaRecorrencia?.toLocaleDateString("pt-br").split("/").join("-") + "T00:00:00";
        }

        const retorno = await this.documentoService.salvarItem(documentoItem);
        documentoItem = retorno.objeto;

        this.props.toast.current.show([
            { severity: 'success', summary: 'Alterações armazenadas com sucesso!' },
        ]);

        this.setState({ carregando: false });

        this.voltar();
    }

    render() {
        return <div>
            <Dialog header="Alerta" visible={this.state.vModalInativar} style={{ width: 400 }} onHide={() => this.setState({ vModalInativar: false, })}>
                <div style={{ display: "flex", flexDirection: 'column', gap: 20 }}>
                    <p>Deseja realmente inativar o compromisso?</p>
                    <div style={{ display: 'flex', gap: 10, justifyContent: "right" }}>
                        <Button label="Sim" onClick={() => {
                            this.alterarSituacao("INATIVO");
                            this.setState({ vModalInativar: false, });
                        }} />
                        <Button label="Não" onClick={() => this.setState({ vModalInativar: false, })} />
                    </div>
                </div>
            </Dialog>

            <main style={{ width: '95%', margin: '20px auto' }}>
                <div style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 20, margin: '20px 0' }}>
                            <Button label={<i className="pi pi-arrow-left"></i>} className="p-button-raised" onClick={() => this.voltar()} />

                            <div>
                                <h1 style={{ fontSize: 22 }}>Detalhe do compromisso</h1>
                                <h2 style={{ fontSize: 20, color: "gray" }}>
                                    {this.state.documentoItem._pessoaNome}
                                </h2>
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'right' }}>
                            {this.state.documentoItem.situacao === "ATIVO" ?
                                <Button label="Inativar compromisso" className="p-button-danger" onClick={() => this.setState({ vModalInativar: true })} />
                                :
                                <Button label="Ativar compromisso" className="p-button-success" onClick={() => this.alterarSituacao("ATIVO")} />
                            }
                        </div>

                        <Button
                            disabled={this.state.carregando}
                            loading={this.state.carregando}
                            label='Salvar'
                            onClick={() => this.salvar(this.state.documentoItem)} />
                    </div>
                </div>

                <div style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3, marginTop: 20 }}>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", gap: 20, marginTop: 10 }}>
                        <div>
                            <label>Nome</label><br />
                            <InputText
                                disabled
                                value={this.state.documentoItem._pessoaNome}
                                style={{ width: '100%' }} />
                        </div>
                        <div>
                            <label>Email</label><br />
                            <InputText
                                disabled
                                value={this.state.documentoItem._pessoaEmail}
                                style={{ width: '100%' }} />
                        </div>                        <div>
                            <label>Projeto</label><br />
                            <InputText
                                disabled
                                value={this.state.documentoItem._itemNome}
                                style={{ width: '100%' }} />
                        </div>
                        <div>
                            <label>Valor</label><br />
                            <InputText
                                disabled
                                value={this.util.formatarValor(this.state.documentoItem.precoUnitario)}
                                style={{ width: '100%' }} />
                        </div>
                        <div>
                            <label>Data do próximo vencimento</label><br />
                            <Calendar
                                style={{ width: '100%' }}
                                value={this.state.documentoItem.dataProximaRecorrencia}
                                onChange={e => {
                                    console.log(e.value);
                                    this.setState({
                                        documentoItem: {
                                            ...this.state.documentoItem,
                                            dataProximaRecorrencia: e.value,
                                        }
                                    });
                                }}
                                dateFormat="dd/mm/yy"
                                placeholder="dd/mm/yyyy" />
                        </div>
                        <div>
                            <label>Data de cadastro</label><br />
                            <InputText
                                disabled
                                value={this.util.formatarDataComTimestamp(this.state.documentoItem.dataCadastro)}
                                style={{ width: '100%' }} />
                        </div>
                        <div>
                            <label>Situação</label><br />
                            <InputText
                                disabled
                                value={this.state.documentoItem.situacao}
                                style={{ width: '100%' }} />
                        </div>
                    </div>
                </div>
            </main>
        </div>
    }
}