import { Component, createRef } from "react";
import { Navigate } from "react-router-dom";
import { Button } from 'primereact/button';
import { Avatar } from 'primereact/avatar';
import logoPallua from '../../../assets/images/logo-pallua.png';
import Util from "../../../util/Util";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import { Messages } from "primereact/messages";
import { Dialog } from "primereact/dialog";
import FormDadosPessoais from "./FormDadosPessoais";
import { Steps } from "primereact/steps";
import FormDadosBancarios from "./FormDadosBancarios";
import FormFotoPerfil from "./FormFotoPerfil";
import { Toast } from "primereact/toast";
import FormEndereco from "./FormEndereco";

export default class Header extends Component {
    util = new Util();
    mensagemDados = createRef();
    mensagemProjeto = createRef();
    toast = createRef();

    listaStep = [{ label: "Dados pessoais" }, { label: "Endereço" }, { label: "Dados bancários" }];

    state = {
        liberarAcesso: {
            login: false,
            dashboard: false,
            compromisso: false,
            financeiro: false,
            projeto: false,
            perfil: false,
            hotsite: false,
        },
        urlBase: '',
        pessoaUsuario: new Pessoa(),
        dialogVisivel: false,
        indexStep: 0
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        const urlBase = await this.util.buscarUrlBaseArquivo();
        const pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");

        if (!pessoaUsuario.contaS2pCodigo || !pessoaUsuario.seqEnderecoPrincipal) {
            this.mensagemDados.current.show([
                { severity: 'error', summary: 'É importante você manter seus dados atualizados para você receber o seu dinheiro. Clique aqui e complete seus Dados pessoais e Dados bancários!', sticky: true, closable: false },
            ])
        } else if (!pessoaUsuario.tags0) {
            this.mensagemProjeto.current.show([
                { severity: 'warn', summary: 'Clique aqui e cadastre seu primeiro projeto', sticky: true, closable: false },
            ])
        }

        this.setState({
            urlBase: urlBase,
            pessoaUsuario: pessoaUsuario,
        })
    }

    navegar(pTela) {
        const url_path = window.location.pathname.split("/")[2];
        if (pTela === url_path) return;

        const liberarAcesso = this.state.liberarAcesso;
        liberarAcesso[pTela] = true;
        this.setState({ liberarAcesso: liberarAcesso });
    }

    sair() {
        this.util.removerLocalStorage("PESSOA_USUARIO");
        this.navegar("hotsite");
    }

    avancarFormulario() {
        const proximoIndexStep = this.state.indexStep + 1

        if (proximoIndexStep === this.listaStep.length) {
            this.toast.current.show([
                { severity: 'success', summary: 'Você completou todos os seus dados!' },
            ]);

            this.mensagemDados.current.clear();

            this.setState({ dialogVisivel: false });
        } else {
            this.setState({ indexStep: proximoIndexStep });
        }
    }

    render() {
        return <>
            {this.state.liberarAcesso.dashboard && <Navigate to="/sistema/dashboard" />}
            {this.state.liberarAcesso.compromisso && <Navigate to="/sistema/compromisso" />}
            {this.state.liberarAcesso.financeiro && <Navigate to="/sistema/financeiro" />}
            {this.state.liberarAcesso.projeto && <Navigate to="/sistema/projeto" />}
            {this.state.liberarAcesso.perfil && <Navigate to="/sistema/perfil" />}
            {this.state.liberarAcesso.hotsite && <Navigate to="/login" />}

            <Dialog
                style={{ width: 600 }}
                visible={this.state.dialogVisivel}
                onHide={() => this.setState({ dialogVisivel: false })}
            >
                <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
                    <Steps
                        model={this.listaStep}
                        activeIndex={this.state.indexStep} />
                    {this.state.indexStep === 0 &&
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
                            <h2>Complete seus dados pessoais</h2>
                            <FormDadosPessoais avancarFormulario={() => this.avancarFormulario()} />
                        </div>
                    }
                    {this.state.indexStep === 1 &&
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
                            <h2>Complete os dados do seu endereço</h2>
                            <FormEndereco avancarFormulario={() => this.avancarFormulario()} />
                        </div>
                    }
                    {this.state.indexStep === 2 &&
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
                            <h2>Complete seus dados bancários</h2>
                            <FormDadosBancarios avancarFormulario={() => this.avancarFormulario()} />
                        </div>
                    }
                </div>
            </Dialog>

            <header style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: 'var(--primary-color)', padding: '10px 20px' }}>
                <img src={logoPallua} alt="pallua" style={{ width: 80, objectFit: 'contain' }} />

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <nav style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 5 }}>
                        {/* <Button className="p-button-link" label="Dashboard" onClick={() => this.navegar("dashboard")} style={{ color: 'white' }} /> */}
                        <Button className="p-button-link" label="Financeiro" onClick={() => this.navegar("financeiro")} style={{ color: 'white' }} />
                        <Button className="p-button-link" label="Compromisso" onClick={() => this.navegar("compromisso")} style={{ color: 'white' }} />
                        <Button className="p-button-link" label="Projeto" onClick={() => this.navegar("projeto")} style={{ color: 'white' }} />
                        <Button className="p-button-link" style={{ padding: 0 }} label={
                            <Avatar label={String(this.state.pessoaUsuario.nome).charAt(0)} image={(this.state.urlBase && this.state.pessoaUsuario.foto) ? this.state.urlBase + this.state.pessoaUsuario.foto : null} shape="circle" style={{ objectFit: 'contain' }}></Avatar>
                        } onClick={() => this.navegar("perfil")} />
                        <Button className="p-button-link" label="Sair" icon={<i className="pi pi-sign-out"></i>} iconPos="right" onClick={() => this.sair()} style={{ color: 'white', gap: 5 }} />
                    </nav>
                </div>
            </header>
            <Messages style={{ cursor: "pointer" }} ref={this.mensagemDados} onClick={() => this.setState({ dialogVisivel: true })} />
            <Messages style={{ cursor: "pointer" }} ref={this.mensagemProjeto} onClick={() => this.navegar("projeto")} />
            <Toast ref={this.toast} />
        </>
    }
}