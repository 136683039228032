import { Button } from "primereact/button";
import { Component } from "react";
import Header from "./components/Header";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import Constante from "../../util/Constante";
import Util from "../../util/Util";
import FinanceiroService from "lirida-back-service/Servicos/Financeiro/FinanceiroService";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";
import Item from "lirida-back-service/Servicos/Item/Item";
import ItemService from "lirida-back-service/Servicos/Item/ItemService";
import CondicaoPagamentoService from 'lirida-back-service/Servicos/CondicaoPagamento/CondicaoPagamentoService';
import CondicaoPagamento from 'lirida-back-service/Servicos/CondicaoPagamento/CondicaoPagamento';
import TipoDocumentoService from 'lirida-back-service/Servicos/TipoDocumento/TipoDocumentoService';
import Financeiro from "lirida-back-service/Servicos/Financeiro/Financeiro";
import { InputText } from "primereact/inputtext";

export default class FinanceiroPg extends Component {
    constante = new Constante();
    util = new Util(this.constante.token, this.constante.url_base);
    financeiroService = new FinanceiroService(this.constante.token, this.constante.url_base);
    itemService = new ItemService(this.constante.token, this.constante.url_base);
    condicaoPagamentoService = new CondicaoPagamentoService(this.constante.token, this.constante.url_base)
    tipoDocumentoService = new TipoDocumentoService(this.constante.token, this.constante.url_base)

    state = {
        urlBaseFinanceiro: '',
        pessoaUsuario: new Pessoa(),
        condicaoPagamento: new CondicaoPagamento(),
        listaFinanceiro: [],
        listaCondicaoPagamento: [],
        listaItem: [],
        carregando: false,
        valorLiquido: 0,
        valorBruto: 0,

        //navigation
        liberarAcessoLinkPagamento: false,

        /* Filtros */
        item: new Item(),
        status: -1,
        dataCadastro_inicial: '',
        dataCadastro_final: '',
        dataProgramada_inicial: '',
        dataProgramada_final: '',
        dataBaixa_inicial: '',
        dataBaixa_final: '',
        dataCredito_inicial: '',
        dataCredito_final: '',
        conteudo: "",
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        this.setState({ carregando: true });
        const urlBaseFinanceiro = await this.util.buscarUrlBaseFinanceiro();
        const pessoaUsuario = await this.util.buscarLocalStorage('PESSOA_USUARIO');

        const retornoBuscarTipoDocumento = await this.tipoDocumentoService.buscarPorSeq(this.constante.seqUsuario, this.constante.seqTipoDocumento);
        const tipoDocumento = retornoBuscarTipoDocumento.objeto;

        const retornoCondicaoPagamento = await this.condicaoPagamentoService.listarFormaPagamento(tipoDocumento.seqCondicaoPagamento);
        const listaCondicaoPagamento = retornoCondicaoPagamento.objeto;

        await this.listarProjeto(pessoaUsuario);
        await this.listar(pessoaUsuario, new Date());

        this.setState({
            urlBaseFinanceiro: urlBaseFinanceiro,
            pessoaUsuario: pessoaUsuario,
            dataCadastro_inicial: new Date(),
            dataCadastro_final: new Date(),
            listaCondicaoPagamento: listaCondicaoPagamento
        });
    }

    async listarProjeto(pPessoa) {
        const listaPesquisa = [];
        let listaItem = [];
        let pessoaUsuario;

        if (pPessoa) {
            pessoaUsuario = pPessoa;
        } else {
            pessoaUsuario = this.state.pessoaUsuario;
        }

        this.setState({
            listaItem: listaItem,
        })

        let pesquisa = new Pesquisa();
        pesquisa.campo = "item.seq_usuario";
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = "";
        pesquisa.tipo = "TEXTO";

        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = "item_relacionamento.seq_tipo_relacionamento";
        pesquisa.conteudo = this.constante.seqTipoRelacionamentoItemDonoProjeto;
        pesquisa.operacao = "AND";
        pesquisa.tipo = "TEXTO";

        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = "item_relacionamento.seq_pessoa_relacionada";
        pesquisa.conteudo = pessoaUsuario._seqPessoa;
        pesquisa.operacao = "AND";
        pesquisa.tipo = "TEXTO";

        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = "item.nome";
        pesquisa.conteudo = this.state.conteudo;
        pesquisa.operacao = "AND";
        pesquisa.tipo = "TEXTO";

        listaPesquisa.push(pesquisa);

        const retorno = await this.itemService.listarComFiltro(listaPesquisa);
        listaItem = retorno.objeto;

        this.setState({ listaItem: listaItem });
    }

    async listar(pPessoa, pData) {
        let pessoaUsuario;
        let dataCadastro_inicial;
        let dataCadastro_final;

        if (pPessoa) {
            pessoaUsuario = pPessoa;
        } else {
            pessoaUsuario = this.state.pessoaUsuario;
        }

        if (pData) {
            dataCadastro_inicial = pData;
            dataCadastro_final = pData;
        } else {
            dataCadastro_inicial = this.state.dataCadastro_inicial;
            dataCadastro_final = this.state.dataCadastro_final;
        }

        const listaPesquisa = [];

        listaPesquisa.push(
            this.util.retornaPesquisa(
                "movimento_financeiro.seq_usuario",
                this.constante.seqUsuario,
                "",
                "TEXTO"
            )
        );

        listaPesquisa.push(
            this.util.retornaPesquisa(
                "movimento_financeiro.seq_pessoa_recebedor",
                pessoaUsuario._seqPessoa,
                "AND",
                "TEXTO"
            )
        );

        if (this.state.conteudo) {
            listaPesquisa.push(
                this.util.retornaPesquisa(
                    "pessoa.nome",
                    this.state.conteudo,
                    "AND",
                    "TEXTO"
                )
            );
        }

        if (this.state.item._seqItem) {
            listaPesquisa.push(
                this.util.retornaPesquisa(
                    "movimento_financeiro.seq_item",
                    this.state.item._seqItem,
                    "AND",
                    "TEXTO"
                )
            );
        }

        if (this.state.status >= 0) {
            if (this.state.status === 0) {
                listaPesquisa.push(
                    this.util.retornaPesquisa(
                        "movimento_financeiro.qtde_baixa",
                        this.state.status,
                        "AND",
                        "NUMERO"
                    )
                );
            } else {
                listaPesquisa.push(
                    this.util.retornaPesquisa(
                        "AND movimento_financeiro.qtde_baixa > 0",
                        this.state.status,
                        "AND",
                        "MANUAL"
                    )
                );
            }
        }

        if (dataCadastro_inicial) {
            listaPesquisa.push(
                this.util.retornaPesquisa(
                    "movimento_financeiro.data_cadastro",
                    this.util.formatarData(dataCadastro_inicial?.toLocaleDateString("pt-br")),
                    "AND",
                    "DATA_INICIAL"
                )
            );
        }

        if (dataCadastro_final) {
            listaPesquisa.push(
                this.util.retornaPesquisa(
                    "movimento_financeiro.data_cadastro",
                    this.util.formatarData(dataCadastro_final?.toLocaleDateString("pt-br")),
                    "AND",
                    "DATA_FINAL"
                )
            );
        }

        if (this.state.dataProgramada_inicial) {
            listaPesquisa.push(
                this.util.retornaPesquisa(
                    "movimento_financeiro.data_programado",
                    this.util.formatarData(this.state.dataProgramada_inicial?.toLocaleDateString("pt-br")),
                    "AND",
                    "DATA_INICIAL"
                )
            );
        }

        if (this.state.dataProgramada_final) {
            listaPesquisa.push(
                this.util.retornaPesquisa(
                    "movimento_financeiro.data_programado",
                    this.util.formatarData(this.state.dataProgramada_final?.toLocaleDateString("pt-br")),
                    "AND",
                    "DATA_FINAL"
                )
            );
        }

        console.log("Data de vencimento")
        console.log(this.state.dataProgramada_inicial, this.state.dataProgramada_final);

        if (this.state.dataBaixa_inicial) {
            listaPesquisa.push(
                this.util.retornaPesquisa(
                    "movimento_financeiro_baixa.data_baixa",
                    this.util.formatarData(this.state.dataBaixa_inicial?.toLocaleDateString("pt-br")),
                    "AND",
                    "DATA_INICIAL"
                )
            );
        }

        if (this.state.dataBaixa_final) {
            listaPesquisa.push(
                this.util.retornaPesquisa(
                    "movimento_financeiro_baixa.data_baixa",
                    this.util.formatarData(this.state.dataBaixa_final?.toLocaleDateString("pt-br")),
                    "AND",
                    "DATA_FINAL"
                )
            );
        }

        if (this.state.dataCredito_inicial) {
            listaPesquisa.push(
                this.util.retornaPesquisa(
                    "movimento_financeiro_baixa.data_credito",
                    this.util.formatarData(this.state.dataCredito_inicial?.toLocaleDateString("pt-br")),
                    "AND",
                    "DATA_INICIAL"
                )
            );
        }

        if (this.state.dataCredito_final) {
            listaPesquisa.push(
                this.util.retornaPesquisa(
                    "movimento_financeiro_baixa.data_credito",
                    this.util.formatarData(this.state.dataCredito_final?.toLocaleDateString("pt-br")),
                    "AND",
                    "DATA_FINAL"
                )
            );
        }

        console.log(listaPesquisa);

        const retornoListaFinanceiro = await this.financeiroService.listarComFiltro(listaPesquisa);
        const listaFinanceiro = retornoListaFinanceiro.objeto;

        let valorLiquido = 0;
        let valorBruto = 0;

        listaFinanceiro.forEach(pFinanceiro => {
            if (pFinanceiro.valorLiquido) {
                valorLiquido = pFinanceiro.valorLiquido + valorLiquido
                valorBruto = pFinanceiro.valorProgramado + valorBruto;
            }
        })

        console.log(valorLiquido)

        this.setState({
            listaFinanceiro: listaFinanceiro,
            carregando: false,
            valorLiquido: valorLiquido,
            valorBruto: valorBruto,
        });
    }

    render() {
        return <div>
            <Header />

            <main style={{ width: '95%', margin: '20px auto' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: '1px solid lightgray', padding: 10, borderRadius: 3 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 20, margin: '20px 0' }}>
                        <h1 style={{ fontSize: 22 }}>Financeiro</h1>
                    </div>
                </div>

                <form
                    onSubmit={e => { e.preventDefault(); this.listar() }}
                    style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3, marginTop: 20, display: 'flex', flexDirection: 'column', gap: 15 }}>

                    <h1 style={{ fontSize: 20 }}>Filtros de busca</h1>

                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 20 }}>
                        <div>
                            <label>Nome da pessoa</label><br />
                            <InputText
                                style={{ width: "100%" }}
                                onChange={e => this.setState({
                                    conteudo: e.target.value
                                })}
                                value={this.state.conteudo} />
                        </div>
                        <div>
                            <label>Projeto</label><br />
                            <Dropdown
                                placeholder="Todos"
                                options={[
                                    { nome: "Todos" },
                                    ...this.state.listaItem
                                ]}
                                optionLabel="nome"
                                value={this.state.item}
                                onChange={e => this.setState({ item: e.value })}
                                style={{ width: '100%', }} />
                        </div>
                        <div>
                            <label>Status</label><br />
                            <Dropdown
                                placeholder="Todos"
                                options={[
                                    { label: 'Todos', value: -1 },
                                    { label: 'Não pago', value: 0 },
                                    { label: 'Pago', value: 1 },
                                ]}
                                value={this.state.status}
                                onChange={e => this.setState({ status: e.value })}
                                style={{ width: '100%', }} />
                        </div>
                        <div>
                            <label>Data cadastro inicial</label><br />
                            <Calendar
                                showButtonBar
                                style={{ width: '100%', }}
                                onChange={e => this.setState({ dataCadastro_inicial: e.value })}
                                value={this.state.dataCadastro_inicial}
                                dateFormat="dd/mm/yy"
                                placeholder="dd/mm/yyyy" />
                        </div>
                        <div>
                            <label>Data cadastro final</label><br />
                            <Calendar
                                showButtonBar
                                style={{ width: '100%', }}
                                onChange={e => this.setState({ dataCadastro_final: e.value })}
                                value={this.state.dataCadastro_final}
                                dateFormat="dd/mm/yy"
                                placeholder="dd/mm/yyyy" />
                        </div>
                        <div>
                            <label>Data de vencimento inicial</label><br />
                            <Calendar
                                showButtonBar
                                style={{ width: '100%', }}
                                onChange={e => this.setState({ dataProgramada_inicial: e.value })}
                                value={this.state.dataProgramada_inicial}
                                dateFormat="dd/mm/yy"
                                placeholder="dd/mm/yyyy" />
                        </div>
                        <div>
                            <label>Data de vencimento final</label><br />
                            <Calendar
                                showButtonBar
                                style={{ width: '100%', }}
                                onChange={e => this.setState({ dataProgramada_final: e.value })}
                                value={this.state.dataProgramada_final}
                                dateFormat="dd/mm/yy"
                                placeholder="dd/mm/yyyy" />
                        </div>
                        <div>
                            <label>Data do pagamento inicial</label><br />
                            <Calendar
                                showButtonBar
                                style={{ width: '100%', }}
                                onChange={e => this.setState({ dataBaixa_inicial: e.value })}
                                value={this.state.dataBaixa_inicial}
                                dateFormat="dd/mm/yy"
                                placeholder="dd/mm/yyyy" />
                        </div>
                        <div>
                            <label>Data do pagamento final</label><br />
                            <Calendar
                                showButtonBar
                                style={{ width: '100%', }}
                                onChange={e => this.setState({ dataBaixa_final: e.value })}
                                value={this.state.dataBaixa_final}
                                dateFormat="dd/mm/yy"
                                placeholder="dd/mm/yyyy" />
                        </div>
                        <div>
                            <label>Data crédito inicial</label><br />
                            <Calendar
                                showButtonBar
                                style={{ width: '100%', }}
                                onChange={e => this.setState({ dataCredito_inicial: e.value })}
                                value={this.state.dataCredito_inicial}
                                dateFormat="dd/mm/yy"
                                placeholder="dd/mm/yyyy" />
                        </div>
                        <div>
                            <label>Data crédito final</label><br />
                            <Calendar
                                showButtonBar
                                style={{ width: '100%', }}
                                onChange={e => this.setState({ dataCredito_final: e.value })}
                                value={this.state.dataCredito_final}
                                dateFormat="dd/mm/yy"
                                placeholder="dd/mm/yyyy" />
                        </div>
                    </div>

                    <div style={{ display: 'flex', alignItems: "center", justifyContent: 'space-between', marginTop: 20 }}>
                        <div style={{ marginTop: 20, display: 'flex', alignItems: 'center', gap: 50 }}>
                            <div>
                                <label>Valor Bruto</label><br />
                                <h2>{this.util.formatarValor(this.state.valorBruto)}</h2>
                            </div>

                            <div>
                                <label>Valor líquido</label><br />
                                <h2>{this.util.formatarValor(this.state.valorLiquido)}</h2>
                            </div>

                            <div>
                                <label>Quantidade</label><br />
                                <h2>{this.state.listaFinanceiro.length}</h2>
                            </div>
                        </div>

                        <div>
                            <Button
                                type="button" label="Limpar filtros" className="p-button-outlined"
                                onClick={() => this.setState({
                                    item: new Item(),
                                    status: -1,
                                    dataCadastro_inicial: '',
                                    dataCadastro_final: '',
                                    dataProgramada_inicial: '',
                                    dataProgramada_final: '',
                                    dataBaixa_inicial: '',
                                    dataBaixa_final: '',
                                    dataCredito_inicial: '',
                                    dataCredito_final: '',
                                    conteudo: "",
                                })} />
                            <Button type="submit" label="Buscar" className="p-button-raised" loading={this.state.carregando} style={{marginLeft: 10}} />
                        </div>
                    </div>
                </form>



                <DataTable
                    style={{ maxWidth: "100%", marginTop: 20, overflowX: "scroll" }}
                    // selectionMode="single"
                    showGridlines
                    removableSort
                    value={this.state.listaFinanceiro}
                    paginator
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10, 20, 50]}>
                    {/* <Column field="_seqMovimentoFinanceiro" header="Id" sortable body={pItem =>
                        <p style={{color: pItem.qtdeBaixa > 0 ? "green" : "red"}}>{pItem._seqMovimentoFinanceiro}</p>
                    }></Column> */}
                    <Column field="_pesNome" header="Nome" sortable body={pItem =>
                        <p style={{ color: pItem.qtdeBaixa > 0 ? "green" : "red" }}>{pItem._pesNome}</p>
                    }></Column>
                    <Column field="_itemNome" header="Nome do projeto" sortable body={pItem =>
                        <p style={{ color: pItem.qtdeBaixa > 0 ? "green" : "red" }}>{pItem._itemNome}</p>
                    }></Column>
                    <Column field="_fpNomeBaixa" header="Forma de pagamento" sortable body={pItem =>
                        <p style={{ color: pItem.qtdeBaixa > 0 ? "green" : "red" }}>{pItem._fpNomeBaixa}</p>
                    }></Column>
                    {/* <Column field="_cfOperacao" header="Operação" sortable></Column> */}
                    {/* <Column field="qtdeBaixa" header="Status" sortable body={pItem =>
                        <p style={{color: pItem.qtdeBaixa > 0 ? "green" : "red"}}>{pItem.qtdeBaixa === 0 ? "Não pago" : "Pago"}</p>
                    }></Column> */}
                    <Column field="valorProgramado" header="Valor" sortable body={pItem =>
                        <p style={{ color: pItem.qtdeBaixa > 0 ? "green" : "red" }}>{this.util.formatarValor(pItem.valorProgramado)}</p>
                    }></Column>
                    <Column field="valorTaxaBaixa" header="Valor da taxa" sortable body={pItem =>
                        <p style={{ color: pItem.qtdeBaixa > 0 ? "green" : "red" }}>{this.util.formatarValor(pItem.valorTaxa)}</p>
                    }></Column>
                    <Column field="valorLiquido" header="Valor líquido" sortable body={pItem =>
                        <p style={{ color: pItem.qtdeBaixa > 0 ? "green" : "red" }}>{this.util.formatarValor(pItem.valorLiquido)}</p>
                    }></Column>
                    <Column field="dataCadastro" header="Data cadastro" sortable body={pItem =>
                        <p style={{ color: pItem.qtdeBaixa > 0 ? "green" : "red" }}>{this.util.formatarDataComTimestamp(pItem.dataCadastro)}</p>
                    }></Column>
                    <Column field="dataProgramado" header="Data de vencimento" sortable body={pItem =>
                        <p style={{ color: pItem.qtdeBaixa > 0 ? "green" : "red" }}>{this.util.formatarDataComTimestamp(pItem.dataProgramado)}</p>
                    }></Column>
                    <Column field="dataBaixa" header="Data do pagamento" sortable body={pItem =>
                        <p style={{ color: pItem.qtdeBaixa > 0 ? "green" : "red" }}>{this.util.formatarDataComTimestamp(pItem._dataBaixa)}</p>
                    }></Column>
                    <Column field="dataCredito" header="Data crédito" sortable body={pItem =>
                        <p style={{ color: pItem.qtdeBaixa > 0 ? "green" : "red" }}>{this.util.formatarDataComTimestamp(pItem._dataCredito)}</p>
                    }></Column>
                </DataTable>
            </main>
        </div>
    }
}