import { Component } from "react";
import Footer from "./components/Footer";
import Header from "./components/Header";

export default class Depoimentos extends Component {
    render() {
        return <div>
            <Header />

            <Footer />
        </div>
    }
}