import { Component } from "react";
import Footer from "./components/Footer";
import Header from "./components/Header";

export default class PoliticaPrivacidade extends Component {
    render() {
        return <div>
            <Header />

            <main style={{ backgroundColor: '#FFBB05', padding: 50, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 50 }}>
                <h1 style={{ color: 'white' }}>Política de privacidade</h1>
                <div style={{ width: 1000, backgroundColor: 'white', borderRadius: 15, border: '1px solid gray', color: 'gray', padding: 20, fontSize: 17, display: "flex", flexDirection: 'column', alignItems: "center", gap: 10, textAligin: "center" }}>
                    <p>Todas as suas informações pessoais recolhidas, serão usadas para o ajudar a tornar a sua visita no nosso site o mais produtiva e agradável possível. A garantia da confidencialidade dos dados pessoais dos utilizadores do nosso site é importante para a Pallua. Todas as informações pessoais relativas a membros, assinantes, clientes ou visitantes que usem a Pallua serão tratadas em concordância com a Lei da Proteção de Dados Pessoais em vigor. A informação pessoal recolhida pode incluir o seu nome, e-mail, número de telefone e/ou telemóvel e/ou outros. O uso da Pallua pressupõe a aceitação deste acordo de privacidade. A equipa da Pallua reserva-se ao direito de alterar este acordo sem aviso prévio. Deste modo, recomendamos que consulte a nossa política de privacidade com regularidade de forma a estar sempre atualizado.</p>
                    <br /><br />

                    <h3>Ligações a sites de terceiros</h3>
                    <p>A Pallua possui ligações para outros sites, os quais, a nosso ver, podem conter informações / ferramentas úteis para os nossos visitantes. A nossa política de privacidade não é aplicada a sites de terceiros, pelo que, caso visite outro site a partir do nosso deverá ler a politica de privacidade do mesmo. Não nos responsabilizamos pela política de privacidade ou conteúdo presente nesses mesmos sites.</p>
                </div>
            </main>

            <Footer />
        </div>
    }
}