import Financeiro from "lirida-back-service/Servicos/Financeiro/Financeiro";
import FinanceiroService from "lirida-back-service/Servicos/Financeiro/FinanceiroService";
import { Component } from "react";
import logoPalluaBranco from '../../assets/images/logo-pallua-branco.png';
import Constante from "../../util/Constante";
import Util from "../../util/Util";

export default class Comprovante extends Component {
    util = new Util();
    constante = new Constante();
    financeiroService = new FinanceiroService(this.constante.token, this.constante.url_base);

    state = {
        financeiro: new Financeiro(),
        urlBase: '',
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        const parametros = this.util.buscarParametros();
        const urlBase = await this.util.buscarUrlBaseArquivo();

        const retornoFinanceiro = await this.financeiroService.buscarPorSeq(this.constante.seqUsuario, parametros.id);
        const financeiro = retornoFinanceiro.objeto;

        console.log("retornoFInanceiro", retornoFinanceiro);

        this.setState({
            financeiro: financeiro,
            urlBase: urlBase,
        });
    }

    render() {
        return <div>
            <header style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: '#FFBB05', padding: '10px 35px' }}>
                <img src={logoPalluaBranco} alt="pallua" style={{ width: 80, objectFit: 'contain' }} />
            </header>

            <main style={{ maxWidth: '90%', width: 400, margin: '0 auto', display: 'flex', flexDirection: 'column', alignItems: 'start', gap: 40, marginTop: 30 }}>
                <div style={{ width: '100%', display: "flex", flexDirection: 'column', gap: 10, alignItems: 'center' }}>
                    <h2>{this.state.financeiro._itemNome}</h2>
                    <h3 style={{ color: 'gray' }}>{this.state.financeiro._itemDescricao}</h3>

                    <img src={this.state.urlBase + this.state.financeiro._itemFoto} alt={this.state.financeiro._itemNome} style={{ width: 300, objectFit: 'contain', margin: '0 auto' }} />
                </div>

                <h1 style={{ fontSize: 22, margin: '0 auto' }}>Comprovante de pagamento</h1>

                <div style={{ width: '100%', display: "flex", flexDirection: 'column', gap: 30 }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <h2 style={{ fontSize: 20 }}>Valor</h2>
                        <p>{this.util.formatarValor(this.state.financeiro.valorProgramado)}</p>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <h2 style={{ fontSize: 20 }}>Destino</h2>
                        <p>{this.state.financeiro._pessoaRecebedorNome}</p>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <h2 style={{ fontSize: 20 }}>Nome</h2>
                        <p>{this.state.financeiro._pesNome}</p>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <h2 style={{ fontSize: 20 }}>Forma de pagamento</h2>
                        <p>{this.state.financeiro._fpNome}</p>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <h2 style={{ fontSize: 20 }}>Data do pagamento</h2>
                        <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                            <p>{this.util.formatarDataComTimestamp(this.state.financeiro._dataBaixa)}</p>
                        </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <h2 style={{ fontSize: 20 }}>Status</h2>
                        <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                            <i className="pi pi-check" style={{ fontSize: 25, color: "green" }}></i>
                            <p>Pago</p>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    }
}