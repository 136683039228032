import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputNumber } from 'primereact/inputnumber';
import { Component } from "react";
import logoPalluaBranco from '../../assets/images/logo-pallua-branco.png';
import seloSeguranca from '../../assets/images/selo-seguranca.png';
import Documento from 'lirida-back-service/Servicos/Documento/Documento';
import DocumentoItem from 'lirida-back-service/Servicos/Documento/DocumentoItem';
import DocumentoService from 'lirida-back-service/Servicos/Documento/DocumentoService';
import DocumentoItemRelacionamento from 'lirida-back-service/Servicos/DocumentoItemRelacionamento/DocumentoItemRelacionamento';
import Financeiro from 'lirida-back-service/Servicos/Financeiro/Financeiro';
import FinanceiroService from 'lirida-back-service/Servicos/Financeiro/FinanceiroService';
import Pesquisa from 'lirida-back-service/Servicos/Pesquisa/Pesquisa';
import Util from '../../util/Util';
import TipoDocumentoService from 'lirida-back-service/Servicos/TipoDocumento/TipoDocumentoService';
import CondicaoPagamentoService from 'lirida-back-service/Servicos/CondicaoPagamento/CondicaoPagamentoService';
import CondicaoPagamento from 'lirida-back-service/Servicos/CondicaoPagamento/CondicaoPagamento';
import Pessoa from 'lirida-back-service/Servicos/Pessoa/Pessoa';
import PessoaService from 'lirida-back-service/Servicos/Pessoa/PessoaService';
import Constante from '../../util/Constante';
import Cobranca from 'lirida-back-service/Servicos/Cobranca/Cobranca';
import ItemService from "lirida-back-service/Servicos/Item/ItemService";
import Item from "lirida-back-service/Servicos/Item/Item";
import ItemTag from "lirida-back-service/Servicos/ItemTag/ItemTag";
import { QRCodeSVG } from "qrcode.react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Checkbox } from "primereact/checkbox";
import ItemRelacionamento from "lirida-back-service/Servicos/ItemRelacionamento/ItemRelacionamento";
import { Helmet } from "react-helmet";

export default class Checkout extends Component {
    constante = new Constante();
    util = new Util(this.constante.token, this.constante.url_base);
    financeiroService = new FinanceiroService(this.constante.token, this.constante.url_base);
    documentoService = new DocumentoService(this.constante.token, this.constante.url_base);
    tipoDocumentoService = new TipoDocumentoService(this.constante.token, this.constante.url_base);
    condicaoPagamentoService = new CondicaoPagamentoService(this.constante.token, this.constante.url_base);
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base);
    itemService = new ItemService(this.constante.token, this.constante.url_base);
    itemTagService = new ItemService(this.constante.token, this.constante.url_base);

    listaMes = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
    listaAno = ['2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030', '2031', '2032', '2033', '2034', '2035'];

    state = {
        urlBase: '',
        pessoa: new Pessoa(),
        condicaoPagamento: new CondicaoPagamento(),
        listaCondicaoPagamento: [],
        item: new Item(),
        itemTag: new ItemTag(),
        listaItemTag: [],
        cobranca: new Cobranca(),
        financeiro: new Financeiro(),
        documento: new Documento(),
        documentoItem: new DocumentoItem(),
        documentoItemRelacionamento: new DocumentoItemRelacionamento(),
        carregando: false,
        cartaoVencimentoMes: '',
        cartaoVencimentoAno: '',
        seqFormaPagamentoCartaoDebito: '',
        seqFormaPagamentoCartaoCredito: '',
        seqFormaPagamentoPix: '',
        seqFormaPagamentoBoleto: '',
        pessoaRecebedor: new ItemRelacionamento(),
        itemValor: undefined,
        geraContrato: false,

        inputError: {
            valor: false,
            nome: false,
            email: false,
            documento: false,
            numero_cartao: false,
            codigo_seguranca: false,
        }
    }



    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        const retornoBuscarParametros = this.util.buscarParametros();
        const urlBase = await this.util.buscarUrlBaseArquivo();
        let itemValor;

        let listaPesquisa = [];

        let pesquisa = new Pesquisa();
        let item = new Item();
        let documento = new Documento();
        let documentoItem = new DocumentoItem();
        let pessoa = new Pessoa();

        if (retornoBuscarParametros?.idc) {
            pesquisa.campo = 'documento_item.seq_documento_item';
            pesquisa.conteudo = retornoBuscarParametros.idc;
            pesquisa.operacao = "";
            pesquisa.tipo = "TEXTO";
            listaPesquisa.push(pesquisa);

            const retornoDocumentoItem = await this.documentoService.listarItemComFiltro(listaPesquisa);
            documentoItem = retornoDocumentoItem.objeto[0];

            const retornoDocumento = await this.documentoService.buscar(this.constante.seqUsuario, documentoItem.seqDocumento);
            documento = retornoDocumento.objeto;

            console.log('documento -->', documento);
            console.log('documento item -->', documentoItem);

            const retornoPessoa = await this.pessoaService.buscarPorSeqPessoa(documento.seqPessoa, this.constante.seqUsuario);
            pessoa = retornoPessoa.objeto;

            console.log('pessoa -->', pessoa);

            listaPesquisa = [];

            pesquisa = new Pesquisa();
            pesquisa.campo = 'item.seq_item';
            pesquisa.conteudo = documentoItem.seqItem;
            pesquisa.operacao = "";
            pesquisa.tipo = "TEXTO";
            listaPesquisa.push(pesquisa);

        } else if (retornoBuscarParametros?.id) {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'item.seq_item';
            pesquisa.conteudo = retornoBuscarParametros.id;
            pesquisa.operacao = "";
            pesquisa.tipo = "TEXTO";
            listaPesquisa.push(pesquisa);
        }

        console.log(listaPesquisa)

        const retornoItem = await this.itemService.listarComFiltro(listaPesquisa);
        item = retornoItem.objeto[0];

        console.log(item);

        const retornoItemRelacionamento = await this.itemService.listarRelacionamento(item);
        const listaItemRelacionamento = retornoItemRelacionamento.objeto;

        console.log(listaItemRelacionamento);

        let pessoaRecebedor;

        for (let i = 0; i < listaItemRelacionamento.length; i++) {
            if (listaItemRelacionamento[i].seqTipoRelacionamento === this.constante.seqTipoRelacionamentoItemDonoProjeto) {
                pessoaRecebedor = listaItemRelacionamento[i];
            }
        }

        console.log('pessoa recebedor ===>>> ', pessoaRecebedor)

        const retornoBuscarTipoDocumento = await this.tipoDocumentoService.buscarPorSeq(item.seqUsuario, this.constante.seqTipoDocumento);
        const tipoDocumento = retornoBuscarTipoDocumento.objeto;
        console.log(tipoDocumento);

        const retornoCondicaoPagamento = await this.condicaoPagamentoService.listarFormaPagamento(tipoDocumento.seqCondicaoPagamento);
        console.log(retornoCondicaoPagamento);

        const listaCondicaoPagamento = retornoCondicaoPagamento.objeto;

        for (const formaPagamento of listaCondicaoPagamento) {
            if (formaPagamento._formaPagamentoOrigem === "2") {
                this.setState({ seqFormaPagamentoCartaoCredito: formaPagamento.seqFormaPagamento })
            }

            if (formaPagamento._formaPagamentoOrigem === "4") {
                this.setState({ seqFormaPagamentoCartaoDebito: formaPagamento.seqFormaPagamento })
            }

            if (formaPagamento._formaPagamentoOrigem === "6") {
                this.setState({ seqFormaPagamentoPix: formaPagamento.seqFormaPagamento })
            }

            if (formaPagamento._formaPagamentoOrigem === "1") {
                this.setState({ seqFormaPagamentoBoleto: formaPagamento.seqFormaPagamento })
            }
        }

        const retornoItemTag = await this.itemService.listarTag(item);
        const listaItemTag = retornoItemTag.objeto;

        const itemTag = listaItemTag[0];

        if (itemTag.valor !== 0) {
            itemValor = itemTag.valor;
        }

        document.querySelector("meta[name='author").setAttribute("content", pessoaRecebedor._pessoaRelacionadaNome);
        document.querySelector("meta[name='description").setAttribute("content", item.descricao);
        document.querySelector("meta[name='og:url").setAttribute("content", window.location.href);
        document.querySelector("meta[name='g:description").setAttribute("content", item.descricao);
        document.querySelector("meta[name='og:image").setAttribute("content", urlBase + item.imagem);
        document.querySelector("meta[name='twitter:site").setAttribute("content", window.location.href);
        document.querySelector("meta[name='twitter:title").setAttribute("content", pessoaRecebedor._pessoaRelacionadaNome);
        document.querySelector("meta[name='twitter:description").setAttribute("content", urlBase + item.descricao);
        document.querySelector("meta[name='twitter:creator").setAttribute("content", pessoaRecebedor._pessoaRelacionadaNome);
        document.querySelector("meta[name='twitter:image").setAttribute("content", urlBase + item.imagem);

        this.setState({
            urlBase: urlBase,
            listaItemTag: listaItemTag,
            item: item,
            itemTag: itemTag,
            itemValor: itemValor,
            listaCondicaoPagamento: listaCondicaoPagamento,
            condicaoPagamento: listaCondicaoPagamento[0],
            cartaoVencimentoAno: this.listaAno[0],
            cartaoVencimentoMes: this.listaMes[0],
            pessoaRecebedor: pessoaRecebedor,
            pessoa: pessoa,
            documento: documento,
            documentoItem: documentoItem,
        });
    }

    async processar() {
        let documento = this.state.documento;
        let documentoItem = this.state.documentoItem;
        const inputError = this.state.inputError;

        const listaChaveInputError = Object.keys(inputError);


        for (let i = 0; i < listaChaveInputError.length; i++) {
            inputError[listaChaveInputError[i]] = false;
        }

        console.log(this.state.itemValor);

        if (!this.state.itemValor) {
            inputError.valor = true;
        }

        if (Number(this.state.itemValor) === 0) {
            inputError.valor = true;
        }

        if (!this.state.pessoa.nome) {
            inputError.nome = true;
        }

        if (!this.state.pessoa.emailPlataformaLirida) {
            inputError.email = true;
        }

        if (this.state.condicaoPagamento.seqFormaPagamento === this.state.seqFormaPagamentoCartaoCredito) {
            if (!this.state.financeiro.cartaoNumero) {
                inputError.numero_cartao = true;
            }
            if (!this.state.financeiro.cartaoDv) {
                inputError.codigo_seguranca = true;
            }
        } else {
            if (!this.state.pessoa.documento) {
                inputError.documento = true;
            } else if (this.state.pessoa.documento.length !== 14 && this.state.pessoa.documento.length !== 18) {
                inputError.documento = true;
            }

            const retorno = await this.util.validarDocumento(this.state.pessoa.documento)

            if (!retorno) {
                inputError.documento = true;
            }
        }

        let inputOK = true;

        for (let i = 0; i < listaChaveInputError.length; i++) {
            if (inputError[listaChaveInputError[i]]) {
                inputOK = false;
            }
        }

        this.setState({ inputError: inputError });

        if (!inputOK) {
            return;
        }


        this.setState({ carregando: true });

        let financeiro = this.state.financeiro;
        let pessoa = this.state.pessoa;
        let cobranca = this.state.cobranca;

        pessoa.seqUsuario = this.constante.seqUsuario;

        if (pessoa.documento) {
            if (pessoa.documento.length === 14) {
                pessoa.tipoDocumento = "CPF";
            }
            if (pessoa.documento.length === 18) {
                pessoa.tipoDocumento = "CNPJ";
            }
        }

        pessoa.seqPessoaPerfil = this.constante.seqPessoaPerfilDoador;

        const retornoPessoa = await this.pessoaService.salvar(pessoa);
        pessoa = retornoPessoa.objeto;

        if (this.state.geraContrato) {
            /* CRIA FUNÇÃO DE ADICIONAR DIAS À DATA ATUAL */
            const NovoDate = Date;

            NovoDate.prototype.addDays = function (days) {
                var date = new Date(this.valueOf());
                date.setDate(date.getDate() + days);
                return date;
            }

            const dataProxRec = new NovoDate().addDays(30);

            documento.seqUsuario = this.constante.seqUsuario;
            documento.seqPessoa = pessoa._seqPessoa;
            documento.seqFormaPagamento = this.state.condicaoPagamento.seqFormaPagamento;
            documento.seqCondicaoPagamento = this.state.condicaoPagamento._seqCondicaoPagamento;
            documento.seqContaBancaria = this.constante.seqContaBancaria;
            documento.seqContaFinanceira = this.constante.seqContaFinanceiraReceber;
            documento.seqPeriodo = "1a589bc2-898e-48d1-a6ee-0999d1d4586a";
            documento.dataInicio = this.util.formatarData(new Date().toLocaleDateString());
            documento.dataValidade = this.util.formatarData(new Date().toLocaleDateString());
            documento.observacao = 'teste'
            documento.seqTipoDocumento = this.constante.seqTipoDocumento;
            documento.seqPessoaRecebedor = this.state.pessoaRecebedor.seqPessoaRelacionada;
            documento.seqTipoDocumentoProcesso = this.constante.seqTipoDocumentoProcessoContratoAtivo;
            documento.dataProgramada = this.util.formatarData(new Date().toLocaleDateString("pt-br"));
            documento.valorLiquido = this.state.itemValor;
            documento.valorTotal = this.state.itemValor;

            console.log(documento.dataProximaRecorrencia)

            const retornoDocumento = await this.documentoService.salvar(documento);
            documento = retornoDocumento.objeto;
            console.log(documento);

            documentoItem.seqUsuario = this.constante.seqUsuario;
            documentoItem.seqDocumento = documento._seqDocumento;
            documentoItem.seqItem = this.state.item._seqItem;
            documentoItem.seqTipoDocumentoProcesso = this.constante.seqTipoDocumentoProcessoContratoAtivo;
            documentoItem.dataProximaRecorrencia = this.util.formatarData(dataProxRec.toLocaleDateString("pt-br"));
            documentoItem.seqPeriodo = this.constante.seqPeriodoRecorrenciaMensal;
            documentoItem.precoUnitario = this.state.itemTag.valor;
            documentoItem.quantidade = 1;

            const retornoDocumentoItem = await this.documentoService.salvarItem(documentoItem);
            documentoItem = retornoDocumentoItem.objeto;
            console.log(documentoItem);

            let documentoItemRelacionamento = this.state.documentoItemRelacionamento;
            documentoItemRelacionamento.seqUsuario = this.constante.seqUsuario;
            documentoItemRelacionamento.seqTipoRelacionamento = this.constante.seqTipoRelacionamentoItemDonoProjeto;
            documentoItemRelacionamento.seqDocumentoItem = documentoItem._seqDocumentoItem;
            documentoItemRelacionamento.seqPessoaRelacionada = this.state.pessoaRecebedor.seqPessoaRelacionada;

            const retornoDocumentoItemRelacionamento = await this.documentoService.salvarRelacionamentoItem(documentoItemRelacionamento);
            documentoItemRelacionamento = retornoDocumentoItemRelacionamento.objeto;
            console.log(documentoItemRelacionamento);

            // const retorno = await this.documentoService.gerarMovimentoFinanceiro(documento);
            // financeiro = retorno.objeto;
        }

        if (this.state.condicaoPagamento.seqFormaPagamento === this.state.seqFormaPagamentoCartaoCredito) {
            financeiro.cartaoVencimento = this.state.cartaoVencimentoMes + '/' + this.state.cartaoVencimentoAno;
        }

        financeiro.seqUsuario = this.constante.seqUsuario;
        financeiro.seqPessoaRecebedor = this.state.pessoaRecebedor.seqPessoaRelacionada;

        if (documento._seqDocumento) {
            financeiro.valorProgramado = documento.valorTotal;
        } else {
            financeiro.valorProgramado = this.state.itemValor;
        }

        financeiro.seqFormaPagamento = this.state.condicaoPagamento.seqFormaPagamento;
        financeiro.seqPessoa = pessoa._seqPessoa;
        financeiro.seqTipoDocumento = this.constante.seqTipoDocumento;
        financeiro.seqContaBancaria = this.constante.seqContaBancaria;
        financeiro.seqContaFinanceira = this.constante.seqContaFinanceiraReceber;
        financeiro.seqItem = this.state.item._seqItem;

        const retornoFinanceiro = await this.financeiroService.salvar(financeiro);
        financeiro = retornoFinanceiro.objeto;


        const retornoCobranca = await this.financeiroService.gerarCobranca(financeiro);
        cobranca = retornoCobranca.objeto;

        console.log(financeiro)
        console.log(cobranca)

        this.setState({
            documento: documento,
            financeiro: financeiro,
            cobranca: cobranca,
            carregando: false,
        })
    }

    render() {
        return <div>
            <header style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: '#FFBB05', padding: '10px 20px' }}>
                <img src={logoPalluaBranco} alt="pallua" style={{ width: 80, objectFit: 'contain' }} />
            </header>

            <main style={{ maxWidth: '90%', width: 600, margin: '0 auto', display: 'flex', flexDirection: 'column', alignItems: 'start', gap: 30, marginTop: 30, }}>
                <div style={{ width: '100%', display: "flex", flexDirection: 'column', gap: 10, alignItems: 'center' }}>
                    <h2>{this.state.item.nome}</h2>
                    <h3 style={{ color: 'gray' }}>{this.state.item.descricao}</h3>

                    <img src={this.state.urlBase + this.state.item.imagem} alt={this.state.item.nome} style={{ width: 300, objectFit: 'contain', margin: '0 auto' }} />
                </div>

                {!this.state.cobranca._seqCobranca && <form onSubmit={e => { e.preventDefault(); this.processar() }} style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 20, margin: '0 auto 200px auto' }}>
                    {this.state.documento._seqDocumento ?
                        <div>
                            <label style={{ fontWeight: "bold" }}>Valor</label><br />
                            <p style={{ marginTop: 10 }}>{this.util.formatarValor(this.state.documento.valorTotal)}</p>
                        </div>
                        : <>
                            <div>
                                <label>Opções de pagamento</label><br />
                                <Dropdown
                                    style={{ width: '100%' }}
                                    options={this.state.listaItemTag}
                                    optionLabel="tag"
                                    value={this.state.itemTag}
                                    onChange={e => this.setState({
                                        itemTag: e.value,
                                        itemValor: Number(e.value.valor) !== 0 ? e.value.valor : undefined
                                    })} />
                            </div>
                            <div>
                                <label>Valor</label><br />
                                <InputNumber
                                    inputStyle={{ borderColor: this.state.inputError.valor && "var(--red-600)" }}
                                    disabled={!this.state.itemTag.opc}
                                    style={{ width: '100%' }}
                                    mode="currency"
                                    currency="BRL"
                                    locale="pt-BR"
                                    value={this.state.itemValor !== 0 ? this.state.itemValor : undefined}
                                    onChange={e => this.setState({ itemValor: e.value })} />
                                {this.state.inputError.valor && <small style={{ color: "var(--red-600)" }}>Valor inválido</small>}
                            </div>

                        </>}
                    {!this.state.pessoa._seqPessoa &&
                        <div>
                            <label>Forma de pagamento</label><br />
                            <Dropdown
                                style={{ width: '100%' }}
                                options={this.state.listaCondicaoPagamento}
                                optionLabel="_formaPagamento"
                                value={this.state.condicaoPagamento}
                                onChange={e => this.setState({ condicaoPagamento: e.value })} />
                        </div>
                    }
                    {this.state.pessoa._seqPessoa ?
                        <div>
                            <label style={{ fontWeight: "bold" }}>Nome</label><br />
                            <p style={{ marginTop: 10 }}>{this.state.pessoa.nome}</p>
                        </div>
                        :
                        <div>
                            <label>Nome</label><br />
                            <InputText
                                style={{ width: '100%' }}
                                value={this.state.pessoa.nome || ''}
                                className={this.state.inputError.nome && "p-invalid"}
                                onChange={e => this.setState({
                                    pessoa: {
                                        ...this.state.pessoa,
                                        nome: e.target.value
                                    }
                                })} />
                            {this.state.inputError.nome && <small style={{ color: "var(--red-600)" }}>Nome inválido</small>}
                        </div>}
                    {this.state.pessoa._seqPessoa ?
                        <div>
                            <label style={{ fontWeight: "bold" }}>Email</label><br />
                            <p style={{ marginTop: 10 }}>{this.state.pessoa.emailPlataformaLirida}</p>
                        </div>
                        :
                        <div>
                            <label>Email ( Recebimento de recibo )</label><br />
                            <InputText
                                disabled={this.state.pessoa._seqPessoa}
                                style={{ width: '100%' }}
                                value={this.state.pessoa.emailPlataformaLirida || ''}
                                className={this.state.inputError.email && "p-invalid"}
                                onChange={e => this.setState({
                                    pessoa: {
                                        ...this.state.pessoa,
                                        emailPlataformaLirida: e.target.value
                                    }
                                })} />
                            {this.state.inputError.email && <small style={{ color: "var(--red-600)" }}>Email inválido</small>}
                        </div>}
                    {!this.state.pessoa._seqPessoa &&
                        <div>
                            <label>Documento (CPF / CNPJ)</label><br />
                            <InputText
                                disabled={this.state.pessoa._seqPessoa}
                                style={{ width: '100%' }}
                                type="tel"
                                placeholder="___.___.___-__"
                                className={this.state.inputError.documento && "p-invalid"}
                                value={this.state.pessoa.documento || ''}
                                onChange={e => this.setState({
                                    pessoa: {
                                        ...this.state.pessoa,
                                        documento: this.util.formatarCPFCNPJ(e.target.value)
                                    }
                                })
                                } />
                            {this.state.inputError.documento && <small style={{ color: "var(--red-600)" }}>Documento inválido</small>}
                        </div>
                    }

                    {this.state.pessoa._seqPessoa &&
                        <div>
                            <label>Forma de pagamento</label><br />
                            <Dropdown
                                style={{ width: '100%' }}
                                options={this.state.listaCondicaoPagamento}
                                optionLabel="_formaPagamento"
                                value={this.state.condicaoPagamento}
                                onChange={e => this.setState({ condicaoPagamento: e.value })} />
                        </div>}

                    {this.state.condicaoPagamento.seqFormaPagamento === this.state.seqFormaPagamentoCartaoCredito &&
                        <>
                            <div>
                                <label>Número do cartão de crédito</label><br />
                                <InputText
                                    type="tel"
                                    value={this.state.financeiro.cartaoNumero || ''}
                                    className={this.state.inputError.numero_cartao && "p-invalid"}
                                    onChange={e => this.setState({
                                        financeiro: {
                                            ...this.state.financeiro,
                                            cartaoNumero: e.target.value
                                        }
                                    })}
                                    style={{ width: '100%' }} />
                                {this.state.inputError.numero_cartao && <small style={{ color: "var(--red-600)" }}>Número do cartão inválido</small>}
                            </div>
                            <div style={{ display: 'flex', gap: 10 }}>
                                <div style={{ width: '50%' }}>
                                    <label>Mês</label><br />
                                    <Dropdown
                                        style={{ width: '100%' }}
                                        options={this.listaMes}
                                        onChange={e => this.setState({ cartaoVencimentoMes: e.value })}
                                        value={this.state.cartaoVencimentoMes} />
                                </div>
                                <div style={{ width: '50%' }}>
                                    <label>Ano</label><br />
                                    <Dropdown
                                        style={{ width: '100%' }}
                                        options={this.listaAno}
                                        onChange={e => this.setState({ cartaoVencimentoAno: e.value })}
                                        value={this.state.cartaoVencimentoAno} />
                                </div>
                            </div>
                            <div>
                                <label>Código de segurança</label><br />
                                <InputText
                                    value={this.state.financeiro.cartaoDv || ''}
                                    className={this.state.inputError.codigo_seguranca && "p-invalid"}
                                    onChange={e => this.setState({
                                        financeiro: {
                                            ...this.state.financeiro,
                                            cartaoDv: e.target.value
                                        }
                                    })}
                                    style={{ width: '100%' }} />
                                {this.state.inputError.codigo_seguranca && <small style={{ color: "var(--red-600)" }}>Código de segurança inválido</small>}
                            </div>
                        </>}
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'end', justifyContent: 'right', gap: 10 }}>
                        {!this.state.documento._seqDocumento &&
                            <div style={{ display: 'flex', alignItems: 'center', gap: 10, }}>
                                <Checkbox
                                    onChange={e => this.setState({ geraContrato: e.target.checked })}
                                    checked={this.state.geraContrato} />
                                <label>Desejo fazer esta doação mensalmente</label>
                            </div>}
                        <Button className="p-button-success" label="Processar" type="submit" loading={this.state.carregando} />
                    </div>

                    <img src={seloSeguranca} alt="selo de segurança" style={{ width: 200, objectFit: 'contain', margin: '0 auto' }} />
                </form>}

                {this.state.cobranca._seqCobranca && this.state.condicaoPagamento.seqFormaPagamento === this.state.seqFormaPagamentoCartaoCredito &&
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 20, margin: '0 auto' }}>
                        {this.state.cobranca.statusOrigem}
                        {this.state.cobranca.situacao === "OK" ?
                            <>
                                <h2>Pagamento autorizado!</h2>
                                <Button
                                    onClick={() => window.open(this.constante.linkComprovante + this.state.financeiro._seqMovimentoFinanceiro, "_blank")}
                                    label="Ver comprovante"
                                />
                            </>
                            :
                            <>
                                <h2 style={{ textAlign: 'center' }}>Houve um problema no processamento do seu cartão. Por favor, tente novamente!</h2>
                            </>
                        }
                        <Button
                            onClick={() => this.setState({
                                cobranca: new Cobranca(),
                                financeiro: new Financeiro(),
                                documento: new Documento(),
                                documentoItem: new DocumentoItem(),
                                pessoa: new Pessoa(),
                            })}
                            label="Voltar"
                            className='p-button-warning'
                        />
                    </div>
                }

                {this.state.cobranca._seqCobranca && this.state.condicaoPagamento.seqFormaPagamento !== this.state.seqFormaPagamentoCartaoCredito &&
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 20, margin: '0 auto' }}>
                        {this.state.cobranca.formaPagamentoOrigem === "6" && <h4 style={{ textAlign: 'center' }}>Aqui está seu código PIX</h4>}
                        {this.state.cobranca.formaPagamentoOrigem === "1" && <h4 style={{ textAlign: 'center' }}>Aqui está seu código de barras</h4>}

                        {this.state.cobranca.formaPagamentoOrigem === "6" && <QRCodeSVG style={{ margin: '0 auto', }} value={this.state.cobranca.codigoPix} />}

                        <div style={{ width: '100%', overflowY: 'scroll', padding: 5, backgroundColor: 'lightgray' }}>
                            {this.state.cobranca.formaPagamentoOrigem === "6" && <h4>{this.state.cobranca.codigoPix}</h4>}
                            {this.state.cobranca.formaPagamentoOrigem === "1" && <h4>{this.state.cobranca.linhaDigitavel}</h4>}
                        </div>

                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 20, alignItems: 'center' }}>
                            <CopyToClipboard
                                text={
                                    this.state.cobranca.formaPagamentoOrigem === "6" && this.state.cobranca.codigoPix ||
                                    this.state.cobranca.formaPagamentoOrigem === "1" && this.state.cobranca.codigoBarras
                                }
                                onCopy={() => alert('Código copiado com sucesso!')}>
                                <Button
                                    style={{ width: '100%' }}
                                    label="Copiar código"
                                    className='p-button-success'
                                />
                            </CopyToClipboard>

                            {this.state.cobranca.formaPagamentoOrigem === "1" ?
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', gap: 20 }}>
                                    <Button
                                        onClick={() => this.setState({ cobranca: new Cobranca() })}
                                        label="Voltar"
                                        className='p-button-warning'
                                    />

                                    <Button
                                        label="Visualizar boleto"
                                        onClick={() => window.open(this.state.cobranca.urlBoletoOrigem, "_blank")}
                                        className='p-button-info'
                                    />
                                </div>
                                :
                                <Button
                                    onClick={() => this.setState({ cobranca: new Cobranca() })}
                                    label="Voltar"
                                    className='p-button-warning'
                                />
                            }
                        </div>
                    </div>
                }
            </main>
        </div>
    }
}