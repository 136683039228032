import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

import Dashboard from './pages/Sistema/Dashboard';
import Projeto from './pages/Sistema/Projeto';
import Compromisso from './pages/Sistema/Compromisso';
import FinanceiroPg from './pages/Sistema/FinanceiroPg';
import Checkout from './pages/Hotsite/Checkout';
import Perfil from './pages/Sistema/Perfil';
import Comprovante from './pages/Sistema/Comprovante';
import Principal from './pages/Hotsite/Principal';
import QuemSomos from './pages/Hotsite/QuemSomos';
import Depoimentos from './pages/Hotsite/Depoimentos';
import Login from './pages/Hotsite/Login';
import Cadastro from './pages/Hotsite/Cadastro';
import PesquisaPg from './pages/Hotsite/PesquisaPg';
import PoliticaPrivacidade from './pages/Hotsite/PoliticaPrivacidade';
import TaxasServicos from './pages/Hotsite/TaxasServicos';
import TermosUso from './pages/Hotsite/TermoUso';
import { locale, addLocale } from 'primereact/api';
import { Helmet } from 'react-helmet';

const root = ReactDOM.createRoot(document.getElementById('root'));

addLocale('br', {
  firstDayOfWeek: 1,
  dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
  dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
  dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
  monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
  monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
  today: 'Hoje',
  clear: 'Limpar',
  dateFormat: "dd/mm/yy"
});

locale('br');

root.render(<BrowserRouter>
    <Routes>
      {/* <Route path="*" element={<Navigate to="/" />} /> */}
      <Route path="/" element={<Principal />} />
      <Route path="/quem_somos" element={<QuemSomos />} />
      <Route path="/depoimentos" element={<Depoimentos />} />
      <Route path="/login" element={<Login />} />
      <Route path="/pesquisa" element={<PesquisaPg />} />
      <Route path="/cadastro" element={<Cadastro />} />
      <Route path="/termos_de_uso" element={<TermosUso />} />
      <Route path="/politica_de_privacidade" element={<PoliticaPrivacidade />} />
      <Route path="/taxas_e_servicos" element={<TaxasServicos />} />
      <Route path="/checkout" element={<Checkout />} />
      <Route path="/comprovante" element={<Comprovante />} />

      <Route path="/sistema/*" element={
        <Routes>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/compromisso" element={<Compromisso />} />
          <Route path="/projeto" element={<Projeto />} />
          <Route path="/financeiro" element={<FinanceiroPg />} />
          <Route path="/perfil" element={<Perfil />} />
        </Routes>
      } />
    </Routes>
  </BrowserRouter>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
